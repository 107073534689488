import { ReactElement, useState, useEffect, useRef, useContext } from 'react';
import { styled, Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from 'oidc-react';
import clsx from 'clsx';
import { Fab, Checkbox, FormControlLabel, Grid, Paper, Typography, Skeleton, CircularProgress, IconButton, Drawer, Box, Accordion, AccordionSummary, AccordionDetails, Chip } from '@mui/material';
import { ICatalog } from '../../interfaces/ICatalog';
import { ProductThum } from './ProductThum';


import SortIcon from '@mui/icons-material/Sort';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { IItem } from '../../interfaces/IItem';
import { AppContext } from '../AppContext';
import { IsAdmin } from '../../helpers/utils';

const drawerWidth = 280;
const drawerTop = 2;

const CatalogEmpty = {
    Category: {
        Id: '',
        ParentId: '',
        IsFolder: false,
        Img: '',
        Code: '',
        Name: '',
        NameEng: '',
        Children: []
    },
    CategoryProperties: [],
    Contact: {
        Id: '',
        Name: '',
        FirstName: '',
        LastName: '',
        MiddleName: '',
        Email: '',
        Phone: '',
        Customer: {
            Id: '',
            Name: '',
            TypePrice: {
                Id: '',
                Name: '',
                NameSite: ''
            },
            Contracts: []
        }
    },
    Items: []
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
        },
        header: {
            padding: theme.spacing(1),
            marginBottom: theme.spacing(2),
            fontWeight: 100,
            borderRadius: 6,
            transition: 'box - shadow 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
            boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px'
        },
        progress: {
            height: 2,
        },
        prices: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        groupIcon: {
            fontSize: '2.125rem',
            lineHeight: '1.235',
            marginRight: theme.spacing(1),
            color: 'primary',
            cursor: 'pointer',
            textShadow: '0 28px 28px rgba(0,0,0,0.08), 0 10px 10px rgba(0,0,0,0.08)',
        },
        direction: {
            transform: 'scaleY(-1)',
        },
        buy: {
            position: 'absolute',
            top: 170,
            right: 8,
            zIndex: 1,
        }
    })
);

const DrawerHeader = styled('div')(({ theme }) => ({
    // display: 'flex',
    display: 'block',
    // alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    // justifyContent: 'flex-end',
}));

interface Filter {
    key: string,
    val: string,
}

export default function Catalog(props: any): ReactElement {
    const ref = useRef(null);
    const classes = useStyles();
    const [showProgress, setShowProgress] = useState(false);
    const { contract, settings, changeSetting } = useContext(AppContext);
    const [catalog, setCatalog] = useState<ICatalog | null>(CatalogEmpty);
    const [items, setItems] = useState<IItem[]>([]);
    const auth = useAuth();
    const skeletons = [0, 1, 2, 3];
    // const [direction, setDirection] = useState('desc');
    const [showFilters, setShowFilters] = useState(false);
    const [height, setHeight] = useState(400);
    const [filters, setFilters] = useState<Filter[]>([]);
    const [showRest, setShowRest] = useState(false);

    useEffect(() => {
        if (!catalog || catalog.Items.length === 0) {
            // setShowProgress(false);
            return;
        }

        // setShowProgress(true);
        if (settings.direction === 'desc') {
            setItems([...catalog.Items.sort(
                (p1, p2) => (p1.Prices[0].Value < p2.Prices[0].Value) ? 1 : (p1.Prices[0].Value > p2.Prices[0].Value) ? -1 : 0
            )]);
        } else {
            setItems([...catalog.Items.sort(
                (p1, p2) => (p1.Prices[0].Value > p2.Prices[0].Value) ? 1 : (p1.Prices[0].Value < p2.Prices[0].Value) ? -1 : 0
            )]);
        }
        // setShowProgress(false);
    }, [settings, catalog]);

    const handleClickSort = () => {
        const _settings = { ...settings };
        if (_settings.direction === 'desc') {
            _settings.direction = 'asc';
        } else {
            _settings.direction = 'desc';
        }
        changeSetting(_settings);
    };

    const handleClickFilter = () => {
        setShowFilters(!showFilters);
    };

    const handleSetFileterValue = (key: string, val: string) => {
        const idx = filters.findIndex(f => f.key === key && f.val === val);
        if (idx === -1) {
            filters.push({ key: key, val: val });
        } else {
            filters.splice(idx, 1);
        }
        setFilters([...filters]);
    };

    const isCheckedFilter = (key: string, val: string) => {
        const idx = filters.findIndex(f => f.key === key && f.val === val);
        return idx > -1;
    };

    useEffect(() => {
        if (!catalog || catalog.Items.length === 0) {
            return;
        }

        if (filters.length === 0) {
            setItems([...catalog.Items]);
        }
        else {
            const filtered = catalog.Items
                .filter((f) => f.Properties.filter(p => filters.filter(k => k.key === p.Name).length > 0 &&
                    filters.filter(v => v.val === p.Value).length > 0).length > 0);
            setItems(filtered);
        }
    }, [filters]);

    useEffect(() => {
        if (ref.current) {
            setHeight((ref.current as HTMLElement).clientHeight);
        }
    });

    useEffect(() => {
        const fetchData = async (id: string, tkn: string, contractId: string) => {
            setShowProgress(true);
            setFilters([]);

            const url = `/api/v1.0/catalog/${id}/${contractId}`;
            setCatalog(CatalogEmpty);
            setItems([]);

            await fetch(url, { headers: { authorization: tkn, accept: 'Accept: application/json' } })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        const catalog = data as ICatalog;
                        setCatalog(catalog);
                    }
                })
                .catch((error) => {
                    console.log('Error', error);
                })
                .finally(() => setShowProgress(false));
        };

        if (auth.userData && contract) {
            const roles = auth.userData.profile.role as string[];
            const isAdmin = IsAdmin(roles, 'Administrator');
            setShowRest(isAdmin);

            fetchData(props.match.params.id, `${auth.userData.token_type} ${auth.userData.access_token}`, contract.Id);
        }
        else {
            setCatalog(CatalogEmpty);
        }
    }, [props.match.params.id, auth.userData, contract]);

    return (
        <div className={classes.root}>


            <Paper className={classes.header}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={10} sm={11}>
                        <Typography
                            variant="h4"
                            style={{ fontWeight: 100 }}>
                            {catalog &&
                                <>
                                    <span onClick={handleClickFilter} title='Фільтри' >
                                        <span className={clsx(classes.groupIcon, `mdi ${catalog?.Category.Img === '' ? 'mdi-shimmer' : catalog?.Category.Img.toLowerCase()}`)}></span>
                                    </span>
                                    {catalog.Category.Name}
                                </>
                            }
                            {showProgress && <small>Завантаження...</small>}
                            &nbsp;
                        </Typography>

                        {!auth.userData &&
                            <Typography variant="h4" gutterBottom align="center" color="error">
                                Access only to authorized users!
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={2} sm={1} sx={{ textAlign: 'right' }}>
                        <IconButton disabled={!catalog} sx={{ mr: 1 }} onClick={handleClickFilter} title='Фільтри'>
                            <FilterAltIcon />
                        </IconButton>
                        <IconButton disabled={!catalog} onClick={handleClickSort} title={`Ціна, за ${settings.direction === 'desc' ? 'спаданням' : 'зростанням'}`}>
                            <SortIcon className={settings.direction !== 'desc' ? classes.direction : ''} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>

            {/* {showProgress &&
                <Grid container direction="row" spacing={2} sx={{ mb: 2 }} >
                    {skeletons.map(item => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={item} >
                            <Box sx={{ height: 434, position: 'relative' }}>
                                <Skeleton variant="rounded" width='100%' height={200} sx={{ mb: 3 }} />
                                <Skeleton width="100%" />
                                <Skeleton width="60%" />

                                <Skeleton width="100%" sx={{ mt: 2 }} />
                                <Skeleton width="100%" />
                                <Skeleton width="100%" />
                                <Skeleton width="60%" />
                                <Fab
                                    className={classes.buy}
                                    disabled
                                    aria-label="shopping cart"
                                >
                                </Fab>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            } */}

            {
                catalog &&
                <div
                    ref={ref}
                    style={{ position: 'relative' }}
                >
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                position: 'absolute',
                                width: drawerWidth,
                                height: 'auto',
                                minHeight: height - drawerTop,
                                top: drawerTop,
                                boxSizing: 'border-box',
                                borderRadius: 1,
                                transition: 'box - shadow 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
                                boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={showFilters}
                    >

                        <Box sx={{ p: 1 }}>
                            <Box>
                                <Grid container direction="row" spacing={2} >
                                    <Grid item xs={10}>
                                        <Typography variant='button' gutterBottom sx={{ pl: 1, pt: 1, width: '100%' }}>Відібрати</Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <IconButton size='small' onClick={() => setShowFilters(false)} title='Закрити'>
                                            <VisibilityOffOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                {filters.length > 0
                                    ? <Chip size='small' sx={{ mr: 1, mb: 1 }}
                                        color='warning'
                                        label="Скасувати фільтери"
                                        variant="outlined"
                                        onDelete={() => setFilters([])}
                                    />
                                    : null
                                }
                            </Box>

                            {filters.map((filter, idx) => <Chip key={idx} size='small' sx={{ mr: 1, mb: 1 }}
                                label={filter.val}
                                variant="outlined"
                                onDelete={() => handleSetFileterValue(filter.key, filter.val)}
                            />)}
                        </Box>

                        <Box>
                            {catalog.CategoryProperties.map((prop, idx) => (
                                <Accordion key={prop.Name}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`prop-content-${idx}`}
                                        id={`prop-header-${idx}`}
                                    >
                                        <Typography variant='body2'>{prop.Name}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            prop.Values.map((val) => <FormControlLabel
                                                sx={{ display: 'block' }}
                                                key={val.Value}
                                                control={<Checkbox size='small' checked={isCheckedFilter(prop.Name, val.Value)} onChange={() => handleSetFileterValue(prop.Name, val.Value)} />}
                                                label={<Typography variant='caption'>{val.Value} ({val.Count})</Typography>} />)
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            ))}

                        </Box>

                    </Drawer>

                    <Grid container direction="row" spacing={2} >
                        {items.map(item => (
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={item.Id} >
                                <ProductThum
                                    item={item}
                                    customerPriceType={contract ? contract.TypePrice : catalog.Contact.Customer.TypePrice}
                                    categoryImg={catalog.Category.Img ? catalog.Category.Img : ''}
                                    showRest={showRest}
                                />
                            </Grid>
                        ))}
                    </Grid>

                </div>
            }

        </div>
    );
}
