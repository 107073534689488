import React, { ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { Container, Grid } from '@mui/material';




const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {

            padding: theme.spacing(6, 0),
            display: 'flex',
            // minHeight: "100vh",
            flex: 'none',
        },
    })
);

export default function Footer(): ReactElement {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Container maxWidth="xl">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item>
                                &nbsp;
                                {/* Footer of application */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
}
