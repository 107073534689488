export class Constants {
  public static authority = 'https://auth.it-link.com.ua/';
  public static client_id = 'local_itlink_web_spa';
  public static redirect_uri = 'https://localhost:5201/signin-callback.html';
  public static silent_redirect_uri = 'https://localhost:5501/silent-renew.html';
  public static post_logout_redirect_uri = 'https://localhost:5201/';
  public static response_type = 'token id_token';
  public static scope = 'openid roles email profile api1';
  public static loadUserInfo = true;
}

export interface IAuthContentProps {
  user: any;
}

export const emptyId = '00000000-0000-0000-0000-000000000000';