import React, { useState, useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
// import clsx from "clsx";
import { IProductImage } from '../../interfaces/IProductImage';
import { Button, MobileStepper } from '@mui/material';
import ReactImageMagnify from 'react-image-magnify';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const host = process.env.REACT_APP_IMG_ROOT;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // flexGrow: 1
        },
        paper: {
            position: 'relative',
            marginBottom: theme.spacing(2),
            minHeight: 456,
            textAlign: 'center'
        },
        img: {
            maxWidth: '100%',
            height: 'auto',
            width: 'auto',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto'
        },
        imgCont: {
            border: 'solid 1px #fff !important',
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0 28px 28px rgba(0,0,0,0.05), 0 10px 10px rgba(0,0,0,0.05)',
            borderRadius: '8px',
            zIndex: 10000,
            opacity: 1
        },
        imageClassName: {
            maxWidth: '100%',
        },
    })
);

interface Props {
    images: IProductImage[]
}

interface IImageDimensions {
    width: number,
    height: number
}

const ProductSlider = ({ images }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [imageDimensions, setImageDimensions] = useState<IImageDimensions>({ width: 0, height: 0 });
    const scale = 2;

    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        const fetchImage = async (activeStep: number) => {
            const src = `${host}assets/files/images/prods/${images[activeStep].FileName}`;
            const img = new Image();
            img.src = src;
            img.onload = () => {
                const imgDimensions: IImageDimensions = { height: img.height, width: img.width };
                setImageDimensions(imgDimensions);
                // console.log(imgDimensions);
            };
        };
        if (images) {
            fetchImage(activeStep);
        }

    }, [activeStep, images]);

    return (
        <div className={classes.root}>
            {/* <Paper className={classes.paper} elevation={0} >
                <img alt={images[activeStep].FileName} className={classes.img} src={`${host}assets/files/images/prods/${images[activeStep].FileName}`} />
            </Paper> */}

            <ReactImageMagnify
                {...{
                    rimProps: {
                        isHintEnabled: true,
                        shouldHideHintAfterFirstActivation: false,
                        enlargedImagePosition: 'over'
                    },
                    smallImage: {
                        alt: images[activeStep].FileName,
                        isFluidWidth: true,
                        src: `${host}assets/files/images/prods/${images[activeStep].FileName}`,
                        srcSet: `${host}assets/files/images/prods/${images[activeStep].FileName}`,
                        // srcSet: src.srcSet,
                        // sizes: "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px"
                    },
                    largeImage: {
                        src: `${host}assets/files/images/prods/${images[activeStep].FileName}`,
                        // sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px',
                        width: imageDimensions.width * scale,
                        height: imageDimensions.height * scale
                    },
                    enlargedImageContainerDimensions: {
                        width: '138%',
                        height: '100%'
                    },
                    // lensStyle: { backgroundColor: "rgba(0,0,0,.4)" },
                    shouldUsePositiveSpaceLens: true,
                    imageClassName: classes.imageClassName,
                    enlargedImageContainerClassName: classes.imgCont
                }}
            />


            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        Next
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Back
                    </Button>
                }
            />

            {/* {images.map((image, idx) => (
                <Paper key={idx} className={classes.paper} elevation={0} variant="outlined">
                    <img alt={image.FileName} className={classes.img} src={`${host}assets/files/images/prods/${image.FileName}`} />
                </Paper>
            ))} */}
        </div>
    );
};

export default ProductSlider;