import React, { useState } from 'react';
import axios from 'axios';
import { Theme, styled } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from 'oidc-react';
import { AppContext } from '../AppContext/AppContext';
import { Box, Fab, Grid, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Snackbar, Button, IconButton } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { useHistory } from 'react-router-dom';
import clsx from 'clsx';


import UploadIcon from '@mui/icons-material/Upload';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface IRow {
    Code: string;
    Qty: number;
    State: string;
}
interface IOrder {
    DocNum: string;
    Date: Date;
    Error: string;
    Rows: IRow[];
}

type Props = {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },

        media: {
            height: 160,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        },
        content: {
            height: 130,
        },
        act_btn: {
            minWidth: 160
        },
        info_btn: {
            right: 8,
            top: 8,
            position: 'absolute'

        },

        cat_img: {
            fontSize: 130,
            color: '#ccc',
            textShadow: '0 28px 28px rgba(0,0,0,0.08), 0 10px 10px rgba(0,0,0,0.08)',
        }
    })
);

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        borderRadius: 4,
        boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px'
    },
}));

export const WgUploadOrder = (props: Props) => {
    const classes = useStyles();
    const history = useHistory();
    // const [file, setFile] = useState<File | null>();
    const [order, setOrder] = useState<IOrder | null>();
    const [showProgress, setShowProgress] = useState(false);
    const authority = useAuth();

    const onClickFileUpload = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            uploadFile(e.target.files[0]);
        }
    };

    const uploadFile = (file: any) => {
        const formData = new FormData();
        formData.append('file', file);

        const token = (authority && authority.userData) ? (`Bearer ${authority.userData.access_token}`) : '';
        const method = 'POST';
        const url = '/api/v1.0/Order/Upload';

        axios
            .request({
                url,
                method,
                data: formData,
                headers: { Authorization: token },
            })
            .then(({ data }) => {
                setOrder(data.Order);
                setShowProgress(false);
                console.log('Data', data.Order);
            });
    };


    return (
        <Card className='it-box' elevation={0}>
            <CardContent>
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Виберіть файл</Typography>
                            {'в форматі: '} <b>{'txt, csv, xls, xlsx'}</b>.{' '}
                            {'Стрічка має пару значень:'}<br />
                            <em>{'код товару та кількість'}</em> <b>{'51848 2'}</b>.
                            <em style={{ paddingTop: 4, display: 'block' }}>{'It\'s very easy. Right? ;)'}</em>
                        </React.Fragment>
                    }
                >
                    <IconButton
                        className={classes.info_btn}
                        size='small'
                        color="primary"
                        aria-label="export order">
                        <InfoOutlinedIcon />
                    </IconButton>
                </HtmlTooltip>
                <img className='wg-img' src="/assets/files/images/hp/order-upload.png" alt="catalog" />
                <Typography variant='h5'>Завантажити</Typography>
                <Typography variant='body2' sx={{ mb: 8 }}>Export order in formats: txt, csv, xls, xlsx </Typography>

                <Box sx={{ mb: 1 }}>
                    <input
                        type="file" name="file" id="file"
                        accept=".txt,.csv,.xls,.xlsx"
                        style={{ display: 'none' }}
                        onChange={onClickFileUpload} />

                    <label htmlFor="file">
                        <Button
                            className={classes.act_btn}
                            color="primary"
                            size='small'
                            variant="contained"
                            component="span"
                            startIcon={<UploadIcon />}
                            sx={{ mr: 1 }}
                        >
                            Замовлення
                        </Button>
                    </label>
                </Box>
            </CardContent>
        </Card>
    );
};