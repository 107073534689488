import React, { useState, useEffect, useCallback } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from 'oidc-react';

import { Box, Card, CardContent, Typography, Button } from '@mui/material';


import { IsAdmin } from '../../helpers/utils';

type Props = {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: theme.spacing(1 / 2),
            padding: theme.spacing(1),
        },
    })
);

export const ClearCache = (props: Props) => {
    const classes = useStyles();
    const auth = useAuth();
    const [cacheKeys, setCacheKeys] = useState<string[]>([]);
    const [isAdmin, setIsAdmin] = useState(false);


    const clearCache = useCallback(async () => {
        if (!auth.userData) {
            return;
        }

        const url = `/api/v1.0/util/clearcache`;
        const token = `Bearer ${auth.userData.access_token}`;
        await fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
            .then((data) => {
                setCacheKeys(data);
                // console.log('DATA', data);
            })
            .catch((error) => {
                console.log('Error', error);
            });

    }, [auth.userData]);

    const handleClearCache = () => {
        clearCache();
    };

    useEffect(() => {
        if (!auth.userData) {
            return;
        }

        if (auth.userData) {
            const roles = auth.userData.profile.role as string[];
            const isAdmin = IsAdmin(roles, 'Administrator');
            setIsAdmin(isAdmin);
        }

    }, [auth.userData]);


    return (
        <div className={classes.root}>
            <Card className='it-box'>
                <CardContent>
                    <Typography display='block' align='center' gutterBottom variant='h5'>Clear catalog items cache</Typography>

                    <Button size='large' color='secondary' variant='outlined' onClick={handleClearCache} disabled={!Boolean(IsAdmin)}>
                        Clear cache
                    </Button>

                    <Box sx={{ pt: 2 }}>
                        {cacheKeys.length > 0 &&
                            <Typography variant='h5' gutterBottom>Clear {cacheKeys.length} keys: </Typography>
                        }

                        {
                            cacheKeys.map((s, i) => (
                                <Typography sx={{ marginRight: 2 }} component='span' key={i}>{s}</Typography>
                            ))
                        }
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};
