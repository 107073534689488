import React, { ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: theme.spacing(3),
            padding: theme.spacing(3)
        },
    })
);

export default function NotFound(): ReactElement {
    const classes = useStyles();
    return (
        <div className={classes.root}>

            <Grid container direction="row" justifyContent="center" alignItems="center" >
                <Grid item >
                    <Typography variant="h2" align="center" gutterBottom >
                        Too bad nothing found, IT-Link!
                    </Typography>
                </Grid>

            </Grid>
        </div>
    );
}
