import React, { useContext, useState, useEffect } from 'react';
import { Theme, useTheme, styled } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import {
    Link, CircularProgress, InputAdornment, Grid, Box, TextField, Typography,
    Button, IconButton, Paper, Menu, MenuItem, Snackbar,
    Alert, AlertColor, AlertTitle, Slide, IconButtonProps, Collapse, Fab,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
// import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'oidc-react';
import ukLocale from 'date-fns/locale/uk';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AppContext } from '../AppContext/AppContext';
import { green, deepOrange } from '@mui/material/colors';
import ClearIcon from '@mui/icons-material/Clear';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { format } from 'date-fns';
import { IItem } from '../../interfaces/IItem';
import { ICatalog } from '../../interfaces/ICatalog';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { IContract } from '../../interfaces/IContract';
import { IResponseSaveOrder, IOrder, ICollisions, IOrderMessage } from '../../interfaces/IOrder';
import { emptyId } from '../../helpers/Constants';

import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { HtmlTooltip } from '../Extention/HtmlTooltip';

const host = process.env.REACT_APP_IMG_ROOT;

const localeMap = {
    uk: ukLocale,
    en: enLocale,
    ru: ruLocale,
};

const acceptedFileTypes: string[] = ['csv', 'xls', 'xlsx', 'txt'];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: theme.spacing(1 / 2),
            padding: theme.spacing(1),
        },
        bigTiltle: {
            fontWeight: 300
        },
        paper: {
            backgroundImage: 'none',
            padding: theme.spacing(2, 2),
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px'
        },
        superscript: {
            position: 'relative',
            marginLeft: 2,
            top: '-0.8em',
            fontSize: '50%',
            fontWeight: 100
        },
        confirm: {
            position: 'relative'
        },
        success_confirm: {
            position: 'absolute',
            top: theme.spacing(4),
            right: 8,
            zIndex: 1
        },
        files: {
            '& .filepond--panel-root': {
                backgroundColor: theme.palette.background.paper,
            },
            '& .filepond--drop-label': {
                color: theme.palette.getContrastText(theme.palette.background.paper)
            }
        },
        col1: {
            minWidth: 50,
            [theme.breakpoints.down('sm')]: {
                minWidth: 50
            },
        },
        col2: {
            width: 90,
            [theme.breakpoints.down('sm')]: {
                minWidth: 70
            },
        },
    })
);

const buttonSaveSx = {
    ...({
        color: '#fff',
        // bgcolor: green[500],
        // '&:hover': {
        //     bgcolor: green[700],
        // },
    }),
};

const buttonConfirmSx = {
    ...({
        color: '#fff',
        paddingRight: 2,
        bgcolor: deepOrange[500],
        '&:hover': {
            bgcolor: deepOrange[700],
        },
    }),
};

const filterOptions = createFilterOptions<IItem>({
    // matchFrom: 'any'

});

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export const Order = () => {
    const transitionDuration = 1000;
    const classes = useStyles();
    const history = useHistory();
    const confirmBtnRef = React.useRef(null);
    const auth = useAuth();
    const theme = useTheme();
    const [token, setToken] = useState('');
    const [locale, setLocale] = useState<keyof typeof localeMap>('uk');
    const { order, contact, contract,
        addRow, updRow, delRow, populateOrder, clearOrder, createOrder,
        changeContract, sendOrderMess
    } = useContext(AppContext);
    const [disableAdd, setDisavleAdd] = useState(false);
    const [addQty, setAddQty] = useState(1);
    const [collisions, setCollisions] = useState<ICollisions | null>(null);

    const [value, setValue] = useState<IItem | null>();
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
    const [options, setOptions] = useState<IItem[]>([]);
    const loading = open && options.length === 0;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const [openMessage, setOpenMessage] = useState(false);
    const [txtMessage, setTxtMessage] = useState('');
    const [severity, setSeverity] = useState<AlertColor>('info');
    const [confirmLoading, setConfirmLoaging] = useState(false);
    const [showOk, setShowOk] = useState(false);
    const [showImport, setShowImport] = useState(false);
    const [files, setFiles] = useState([]);
    // const [pond, setPond] = useState<any>(undefined);

    const handleAddToOrder = () => {
        if (value) {
            addRow(value, addQty);
        }
    };

    const handleDelRow = (lineNum: number) => {
        if (!delRow) {
            return;
        }
        delRow(lineNum);
    };

    const handleUpdQty = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        if (order.Rows.length > 0) {
            const qty: number = parseInt(e.target.value);
            updRow(idx, qty);
        }
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const getPriceValue = (item: IItem) => {
        let curentContract: IContract | null = null;

        if (order && order.Contract) {
            curentContract = order.Contract;
        } else if (contract) {
            curentContract = contract;
        }

        if (curentContract) {
            const price = item.Prices.find(p => p.Id === curentContract?.TypePrice.Id);
            if (price) {
                return price.Value;
            }
        }

        return 99999999;
    };

    const handleCloseMessage = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenMessage(false);
    };

    const handleClearOrder = () => {
        clearOrder();
    };

    const handleSaveOrder = () => {
        saveOrder(false);
    };

    const handleConfirmOrder = () => {
        setOpenConfirmDlg(true);
        // saveOrder(true);
    };

    const saveOrder = async (confirmed: boolean) => {
        if (!auth.userData || !order) {
            return;
        }

        if (order.Id === emptyId && order.Rows.length === 0) {
            return;
        }

        setConfirmLoaging((confirmLoading) => !confirmLoading);

        const action = order.Id === emptyId ? 'create' : 'update';
        const url = `/api/v1.0/order/${action}/${order.Id}`;
        const token = `Bearer ${auth.userData.access_token}`;
        const method = order.Id === emptyId ? 'POST' : 'PUT';

        const savedOrder = { ...order };
        savedOrder.Confirmed = confirmed;

        setShowOk(false);

        await fetch(url,
            {
                method: method,
                //credentials: 'include',
                body: JSON.stringify(savedOrder),
                headers: {
                    authorization: token,
                    // credentials: 'include',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                }
            },
        )
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    const responseSaveOrder = data as IResponseSaveOrder;
                    if (responseSaveOrder.Order) {

                        populateOrder(responseSaveOrder.Order);
                        setCollisions(responseSaveOrder.Collisions);
                        if (responseSaveOrder && responseSaveOrder.Collisions && responseSaveOrder.Collisions.ProductRowsCollisions) {
                            const hasError = responseSaveOrder.Collisions.ProductRowsCollisions.length > 0;

                            if (!hasError) {
                                setShowOk(true);
                                setTimeout(() => {
                                    setShowOk(false);
                                }, transitionDuration);

                                if (responseSaveOrder.Order.Confirmed) {
                                    // console.log('Redirect to thank order', responseSaveOrder.Order);
                                    // const saved = { ...responseSaveOrder.Order };
                                    createOrder();
                                    history.push('/thank', { order: responseSaveOrder.Order });
                                }
                            }

                            setSeverity('error');
                            setTxtMessage('Не достатьня кількість товару');
                            setOpenMessage(hasError);
                        }

                        if (auth.userData?.profile.email) {
                            const message: IOrderMessage = {
                                action: confirmed ? 'confirm_order' : 'save_order',
                                order: responseSaveOrder.Order,
                                s_hash: auth.userData.profile.s_hash
                            };
                            sendOrderMess(auth.userData.profile.email, JSON.stringify(message));
                        }
                    }
                }
            })
            .catch((error) => {
                console.log('Error checkout approve', error);
            })
            .finally(() => {
                setConfirmLoaging((confirmLoading) => !confirmLoading);
            });
    };

    const handleSelectContract = (id: string) => {
        if (order && contact) {
            const selected: IContract | undefined = contact.Customer.Contracts.find(item => item.Id === id);
            if (selected) {
                changeContract(selected);
            }
        }
    };

    const errorRow = (productId: string) => {
        if (!collisions || !collisions.ProductRowsCollisions || collisions.ProductRowsCollisions.length === 0) {
            return -1;
        }
        return collisions.ProductRowsCollisions.findIndex(item => item.ProducId === productId);
    };

    const errorRowTxt = (productId: string) => {
        if (!collisions || !collisions.ProductRowsCollisions || collisions.ProductRowsCollisions.length === 0) {
            return '';
        }

        const idx = collisions.ProductRowsCollisions.findIndex(item => item.ProducId === productId);
        if (idx === -1) {
            return '';
        }
        return `⚠︎ ${collisions.ProductRowsCollisions[idx].Qty} ${collisions.ProductRowsCollisions[idx].State}`;
    };

    const fixError = () => {
        if (collisions && collisions.ProductRowsCollisions && collisions.ProductRowsCollisions.length > 0) {
            for (let i = 0; i < collisions.ProductRowsCollisions.length; i++) {
                const errorRow = collisions.ProductRowsCollisions[i];
                const exist = order.Rows.find(row => row.ProducId === errorRow.ProducId);
                if (exist) {

                    if (errorRow.Qty > 0) {
                        updRow(exist.LineNumber, errorRow.Qty);
                    } else {
                        delRow(exist.LineNumber);
                    }
                }
            }
            const _collisions = { ...collisions };
            _collisions.ProductRowsCollisions = [];

            setCollisions(_collisions);
            setOpenMessage(false);
        }
    };

    // useEffect(() => {
    //     if (order.Contract?.Id === contract?.Id) {
    //         setDisavleAdd(false);
    //     } else {
    //         setDisavleAdd(true);
    //     }
    // }, [order, contract]);

    useEffect(() => {
        let active = true;

        if (token === '') {
            return undefined;
        }

        if (inputValue === '' || inputValue.length < 3) {
            setOptions([]);
            return undefined;
        }

        if (!loading) {
            return undefined;
        }

        if (auth && auth.userData) {
            (async () => {
                let active = true;
                const url = `/api/v1.0/catalog/search/${inputValue}/${order.Contract?.Id}`;
                setOptions([]);
                await fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data) {
                            const catalog: ICatalog = data as ICatalog;
                            // console.log(catalog);
                            setOptions(catalog.Items);
                        }
                    })
                    .catch((error) => {
                        console.log('Error', error);
                    });

            })();
        }

        return () => {
            active = false;
        };
    }, [token, loading, inputValue]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        const fetchToken = async (tkn: string) => {
            setToken(tkn);
        };

        if (auth.userData) {
            fetchToken(`${auth.userData.token_type} ${auth.userData.access_token}`);
        }

    }, [auth.userData]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale]} >
            <div className={classes.root}>
                {order && contact && contract &&
                    <Grid
                        container
                        spacing={1}
                        sx={{ mb: 1 }}
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        {/* Order common attributes */}
                        <Grid item xs={12} sm={8} md={10}>
                            <Paper className={clsx(classes.paper)} sx={{ mb: 1 }} >
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid item xs={12} sm={4} md={2}>
                                        <TextField
                                            label='Замовлення'
                                            // disabled
                                            fullWidth
                                            value={order.Number === '' ? 'НОВЕ' : order.Number}
                                            size="small"
                                            InputProps={{
                                                readOnly: true,
                                                startAdornment: <InputAdornment position="start">№</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={6}>
                                        <TextField
                                            label='Клієнт'
                                            // disabled
                                            fullWidth
                                            value={order.Customer?.Name}
                                            size="small"
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: <InputAdornment position="end"><MoreHorizIcon /></InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            label='Договір замовлення'
                                            fullWidth
                                            value={order.Contract ? order.Contract.Name : ''}
                                            size="small"
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: <InputAdornment
                                                    position="end"
                                                >
                                                    {
                                                        order.Rows.length === 0 ?
                                                            <IconButton
                                                                onClick={handleOpenMenu}
                                                                sx={{ p: 1 / 8 }}
                                                            >
                                                                <MoreHorizIcon />
                                                            </IconButton>
                                                            :
                                                            <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        {'Для зміни договору:'}<br /> <b>{'видаліть усі товари'}</b>{' з замовлення'}.<br />
                                                                        <em style={{ paddingTop: 4, display: 'block', textAlign: 'right' }}>{'Будьте уважні'}.</em>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <IconButton
                                                                    sx={{ p: 1 / 8 }}
                                                                >
                                                                    <InfoOutlinedIcon color='warning' />
                                                                </IconButton>
                                                            </HtmlTooltip>
                                                    }
                                                </InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>

                            {/* Search products & add to order & import */}
                            <Paper className={clsx(classes.paper)}  >
                                <Grid
                                    container
                                    spacing={2}
                                    alignItems='center'
                                >
                                    <Grid item xs={12} sm={8}>
                                        <Autocomplete
                                            disabled={disableAdd}
                                            id="asynchronous-items"
                                            filterOptions={(x) => x}
                                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.FullName)}
                                            onChange={(event: any, newValue: IItem | null) => {
                                                setValue(newValue);
                                            }}
                                            clearOnEscape={true}
                                            options={options}
                                            loading={loading}
                                            autoComplete
                                            includeInputInList
                                            sx={{ width: '100%' }}
                                            open={open}
                                            onOpen={() => {
                                                setOpen(true);
                                            }}
                                            onClose={() => {
                                                setOpen(false);
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                setOptions([]);
                                                setInputValue(newInputValue);
                                            }}

                                            renderOption={(props, option) => {
                                                const matches = match(option.FullName, inputValue);
                                                const parts = parse(option.FullName, matches);
                                                return (
                                                    <Box component="li"  {...props} sx={{ fontSize: '.9em' }}>

                                                        <Grid container alignItems="center" spacing={2}>
                                                            <Grid item >
                                                                {option.Image !== '' ?
                                                                    <img
                                                                        style={{ width: 40 }}
                                                                        loading="lazy"
                                                                        src={`${host}assets/files/images/prods/${option.Image}`}
                                                                        alt=""
                                                                    /> : <p style={{ width: 40 }}></p>}
                                                            </Grid>
                                                            <Grid item xs={3} sm={1} >
                                                                {(Math.ceil(getPriceValue(option) * 100) / 100).toFixed(2)}
                                                            </Grid>
                                                            <Grid item xs={6} sm={9} >
                                                                <Typography variant="body2" display="block">
                                                                    {parts.map((part, index) =>
                                                                        part.highlight ? (
                                                                            <span key={index} style={{ fontWeight: 600 }}>
                                                                                {part.text}
                                                                            </span>
                                                                        ) : (
                                                                            <strong key={index} style={{ fontWeight: 400 }}>
                                                                                {part.text}
                                                                            </strong>
                                                                        ),
                                                                    )}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Пошук товарів"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems='center'
                                        >
                                            <Grid item xs={5} sm={6}>
                                                {value &&
                                                    <TextField
                                                        disabled={disableAdd}
                                                        label='Кількість'
                                                        fullWidth
                                                        value={addQty}
                                                        InputProps={{
                                                            type: 'number',
                                                            startAdornment: <InputAdornment position="start">шт</InputAdornment>,
                                                            inputProps: { min: '1', step: '1' }
                                                        }}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddQty(parseInt(e.target.value))}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                {value &&
                                                    <Button
                                                        disabled={disableAdd}
                                                        size='large'
                                                        fullWidth
                                                        variant='contained'
                                                        color='success'
                                                        onClick={handleAddToOrder}
                                                        sx={{ color: '#fff' }}
                                                    >
                                                        Добавити
                                                    </Button>
                                                }
                                            </Grid>
                                            <Grid item xs={3} sm={2}>
                                                <ExpandMore
                                                    expand={showImport}
                                                    onClick={() => setShowImport(!showImport)}
                                                    aria-expanded={showImport}
                                                    aria-label="import-to-invoce"
                                                    title='Імпорт товарів в замовлення'
                                                >
                                                    <SettingsIcon />
                                                </ExpandMore>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* Confirm order */}
                        <Grid item xs={12} sm={4} md={2}>
                            <Paper className={clsx(classes.paper, classes.confirm)} sx={{ height: '100%', textAlign: 'center' }} >
                                <Typography color="primary" gutterBottom variant="h3" sx={{ pt: 1 }}>
                                    <span style={{ fontWeight: 100 }}>{parseInt(`${order.OrderTotal}`)}</span><span className={classes.superscript}>.{(Math.round(order.OrderTotal * 100) / 100).toFixed(2).split('.')[1]}</span>
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 1 }}>
                                    <Box className={classes.success_confirm}>
                                        <Slide
                                            timeout={transitionDuration}
                                            direction="up"
                                            in={showOk}
                                            container={confirmBtnRef.current}
                                        >
                                            <ThumbUpOffAltIcon color='warning' sx={{ fontSize: 50 }} />
                                        </Slide>
                                    </Box>

                                    <Box sx={{ position: 'relative', mr: 1 }}>
                                        <Fab
                                            aria-label="save"
                                            // color="inherit"
                                            color='success'
                                            sx={buttonSaveSx}
                                            size="small"
                                            onClick={handleSaveOrder}
                                            title='Зберегти замовлення'
                                        >
                                            <SaveIcon />
                                        </Fab>
                                        {confirmLoading && (
                                            <CircularProgress
                                                size={52}
                                                sx={{
                                                    color: green[500],
                                                    position: 'absolute',
                                                    top: -6,
                                                    left: -6,
                                                    zIndex: 1,
                                                }}
                                            />
                                        )}
                                    </Box>

                                    <Box sx={{ position: 'relative' }}>
                                        <Fab
                                            ref={confirmBtnRef}
                                            disabled={!order || order.Rows.length === 0 ? true : false}
                                            variant="extended"
                                            aria-label="save"
                                            color="inherit"
                                            sx={buttonConfirmSx}
                                            size="small"
                                            onClick={handleConfirmOrder}
                                            title='Підтвердити замовлення'
                                        >
                                            <CheckIcon sx={{ mr: 1 / 2 }} />
                                            Підтвердити
                                        </Fab>
                                        {confirmLoading && (
                                            <CircularProgress
                                                size={46}
                                                sx={{
                                                    color: deepOrange[200],
                                                    position: 'absolute',
                                                    top: -6,
                                                    left: -6,
                                                    zIndex: 10000,
                                                }}
                                            />
                                        )}
                                    </Box>

                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                }

                {/* Import to order form file */}
                <Collapse in={showImport} timeout="auto" unmountOnExit>
                    <Paper className={clsx(classes.paper)} sx={{ mb: 1 }} >
                        <Grid
                            container
                            spacing={1}
                            justifyContent="center"
                        >
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                                <Button
                                    size='large'
                                    variant='outlined'
                                    color='error'
                                    fullWidth
                                    sx={{ py: 1 * 1.5 }}
                                    onClick={handleClearOrder}
                                >
                                    Видалити усі позиції замовлення
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} lg={6}>
                                <FilePond
                                    // ref={(ref) => setPond(ref)}
                                    credits={false}
                                    files={files}
                                    name="files"
                                    maxFiles={1}
                                    dropOnPage
                                    dropValidation
                                    acceptedFileTypes={acceptedFileTypes}
                                    labelIdle="Drag & Drop your file or <span class='filepond--label-action'>Browse</span>"
                                    server={{
                                        // fake server to simulate loading a 'local' server file and processing a file
                                        process: (fieldName, file, metadata, load) => {
                                            const formData = new FormData();
                                            formData.append('file', file);
                                            formData.append('order', JSON.stringify(order));

                                            const url = '/api/v1.0/order/ImportFormFile';
                                            fetch(url,
                                                {
                                                    method: 'POST',
                                                    body: formData,
                                                    headers: { Authorization: token }
                                                })
                                                .then(response => response.json())
                                                .then(data => {
                                                    load(data);
                                                    if (data) {
                                                        const responseSaveOrder = data as IResponseSaveOrder;
                                                        if (responseSaveOrder.Order) {
                                                            populateOrder(responseSaveOrder.Order);
                                                        }
                                                    }
                                                })
                                                .catch(error => {
                                                    console.error(error);
                                                });
                                        },
                                        // load: (source, load) => {
                                        //     // simulates loading a file from the server
                                        //     fetch(source)
                                        //         .then((res) => res.json())
                                        //         .then(load);
                                        // },
                                    }}

                                // server=
                                // {{
                                //     process: {
                                //         url: '/api/v1.0/order/import',
                                //         headers: {
                                //             authorization: token,
                                //         },
                                //         ondata: (formData) => {
                                //             formData.append('order', JSON.stringify(order));
                                //             return formData;
                                //         }
                                //     }
                                // }}

                                // onupdatefiles={(fileItems) => {
                                //     for (let i = 0; i < fileItems.length; i++) {
                                //         const fileItem = fileItems[i];
                                //     }
                                // }}
                                />

                            </Grid>
                        </Grid>


                    </Paper>
                </Collapse>

                {order &&
                    <Paper className={clsx('it-box', classes.paper)} >
                        {/* Errors */}
                        {
                            collisions && collisions.ProductRowsCollisions && collisions.ProductRowsCollisions.length > 0 &&
                            <Grid
                                container
                                spacing={2}
                                alignItems='center'
                                sx={{ pt: 2 }}
                            >
                                <Grid item xs={12} >
                                    <Button
                                        color='warning'
                                        variant='outlined'
                                        onClick={fixError}
                                        startIcon={<WarningAmberIcon />}
                                    >
                                        Виправити кількість у всіх позиціях замовлення
                                    </Button>
                                </Grid>
                            </Grid>
                        }

                        {/* Header table */}
                        <Grid
                            container
                            spacing={2}
                            alignItems='center'
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                py: 1,
                                borderBottom: theme.palette.mode === 'light' ? '1px solid #eee' : '1px solid #777',
                                fontWeight: 'bold', fontSize: '.9em'
                            }}
                        >
                            <Grid item className={classes.col1}>№</Grid>
                            <Grid item className={classes.col2}></Grid>
                            <Grid item sx={{ width: 110 }}>
                                <div><small>Код</small></div>
                                <div>Арт.</div>
                            </Grid>
                            <Grid item xs={12} sm={5}>Найменування</Grid>
                            <Grid item xs={2} sm={1}><span style={{ paddingLeft: 12 }}>К-сть</span></Grid>
                            <Grid item xs={2} sm={1} sx={{ textAlign: 'right' }}>Ціна</Grid>
                            <Grid item xs={3} sm={2} sx={{ textAlign: 'right' }}>Сума</Grid>
                            <Grid item></Grid>
                        </Grid>

                        {/* Details */}
                        {
                            order.Rows.map((row) => (
                                <Grid
                                    key={row.LineNumber}
                                    container
                                    spacing={3}
                                    alignItems='center'
                                    sx={{ py: 1, borderBottom: theme.palette.mode === 'light' ? '1px solid #eee' : '1px solid #777' }}
                                >
                                    <Grid item className={classes.col1}>{row.LineNumber}</Grid>

                                    <Grid item className={classes.col2}>
                                        {row.ProductImage !== '' &&
                                            <Link
                                                color="inherit"
                                                component="button"
                                                onClick={() => history.push(`/catalog/item/${row.ProductCode}`)
                                                }
                                            >
                                                <img
                                                    loading="lazy"
                                                    style={{ width: 'auto', height: 32 }}
                                                    src={`${host}assets/files/images/prods/${row.ProductImage}`}
                                                    alt={row.ProductCode}
                                                />
                                            </Link>

                                        }
                                    </Grid>

                                    <Grid item xs={6} sm={2} md={1}>
                                        <Typography display='block' variant='caption'>{row.ProductCode}</Typography>
                                        <Typography display='block' variant='body2' >{row.ProductArticle}</Typography>
                                    </Grid>

                                    <Grid item sx={{ display: { xs: 'flex', md: 'none' } }}>
                                        <IconButton
                                            size='small'
                                            onClick={() => handleDelRow(row.LineNumber)}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid item xs={12} sm={5}>
                                        <Typography variant='body2' >
                                            {row.ProductName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={1} sx={{ textAlign: 'right' }}>
                                        <TextField
                                            error={errorRow(row.ProducId) !== -1}
                                            helperText={errorRowTxt(row.ProducId)}
                                            fullWidth
                                            value={row.Qty}
                                            size="small"
                                            InputProps={{
                                                type: 'number',
                                                inputProps: { min: '1', step: '1' }
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdQty(e, row.LineNumber)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} sm={1} sx={{ textAlign: 'right' }}>{(Math.ceil(row.SalePrice * 100) / 100).toFixed(2)}</Grid>
                                    <Grid item xs={4} sm={2} sx={{ textAlign: 'right' }}>{(Math.ceil(row.Total * 100) / 100).toFixed(2)}</Grid>
                                    <Grid item sx={{ display: { xs: 'none', md: 'flex' } }}>
                                        <IconButton
                                            onClick={() => handleDelRow(row.LineNumber)}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))
                        }

                        {/* Footer */}
                        <Grid
                            container
                            spacing={2}
                            alignItems='center'
                            sx={{
                                pt: 2
                            }}
                        >
                            <Grid item xs={7} sm={9} >
                                <Typography display='block' textAlign='right'>Проміжний підсумок:</Typography>
                                {/* <Typography display='block' textAlign='right' gutterBottom>Знижка:</Typography>
                                <Typography display='block' textAlign='right' gutterBottom>Доставка:</Typography> */}
                                <Typography
                                    variant='h5'
                                    display='block'
                                    textAlign='right'
                                    sx={{ fontWeight: 100, mt: 2 }}
                                >
                                    Загалом до сплати:
                                </Typography>
                            </Grid>
                            <Grid item xs={5} sm={3}>
                                <Box sx={{ pr: 8 }}>
                                    <Typography variant='subtitle2' display='block' textAlign='right' gutterBottom>{(Math.ceil(order.OrderTotal * 100) / 100).toFixed(2)}</Typography>
                                    {/* <Typography display='block' textAlign='right' gutterBottom>{(Math.ceil(order.Discount * 100) / 100).toFixed(2)}</Typography>
                                    <Typography display='block' textAlign='right' gutterBottom>{(Math.ceil(order.Discount * 100) / 100).toFixed(2)}</Typography> */}
                                    {/* <Divider sx={{ pb: 2 }} /> */}
                                    <Typography
                                        variant='h5'
                                        display='block'
                                        textAlign='right'
                                        sx={{ mt: 2 }}
                                    >
                                        {(Math.ceil(order.OrderTotal * 100) / 100).toFixed(2)}
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    </Paper>
                }

                {contact && order &&
                    <Menu
                        anchorEl={anchorEl}
                        id="customer-contracts-menu"
                        open={openMenu}
                        onClose={handleCloseMenu}
                        onClick={handleCloseMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        {contact.Customer.Contracts.map((item) => (
                            <MenuItem key={item.Id} onClick={(e) => handleSelectContract(item.Id)}>
                                {order?.Contract?.Id === item.Id ? <CheckIcon sx={{ mr: 1 }} /> : null} {item.Name}
                            </MenuItem>
                        ))}

                    </Menu>
                }

                <Dialog
                    open={openConfirmDlg}
                    onClose={() => setOpenConfirmDlg(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {'Підтвердити замовлення?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <b>Увага!</b> Після підтвердження замовлення, вносити зміни буде не можливо.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenConfirmDlg(false)}>Скасувати</Button>
                        <Button color='warning' startIcon={<CheckIcon />} onClick={() => { setOpenConfirmDlg(false); saveOrder(true); }} autoFocus>
                            Підтвердити
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={openMessage}
                    autoHideDuration={15000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onClose={handleCloseMessage}
                >
                    <Alert
                        onClose={handleCloseMessage}
                        variant="filled"
                        severity={severity}
                        sx={{ width: '100%' }}
                        action={
                            <Button
                                color="inherit"
                                variant="outlined"
                                size="small"
                                onClick={fixError}
                            >
                                Виправити
                            </Button>
                        }
                    >
                        <AlertTitle>{txtMessage}</AlertTitle>
                        {collisions && collisions.ProductRowsCollisions && collisions.ProductRowsCollisions.length > 0 &&
                            <div>
                                {collisions.ProductRowsCollisions.map((item) => <Typography variant='body2'> <strong>Кіл.: [{item.Qty}]</strong> {item.ProductCode} {item.ProductName}</Typography>)}
                            </div>
                        }
                    </Alert>
                </Snackbar>

            </div>
        </LocalizationProvider>
    );
};