import React, { ReactElement } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { ClickAwayListener, Grow, Paper, Popper, Breakpoint, Grid, Tabs, Tab, Divider, IconButton, Button } from '@mui/material';
import { NavLink, useHistory } from 'react-router-dom';
import { ICategory } from '../../interfaces/ICategory';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

type BreakpointOrNull = Breakpoint | null;


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    items: ICategory[];
    onClose: any;
}

interface Props {
    categories: ICategory[]
}

function useWidth() {
    const theme: Theme = useTheme();
    const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        category: {
            fontSize: '.9em',
        },
        wrapper: {
            alignItems: 'flex-start',
            textTransform: 'none',
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(15),
            marginRight: theme.spacing(1),
            '&:focus': {
                opacity: 1,
            },
        },
        popoverPaper: {
            overflowX: 'hidden',
            width: '100%',
            height: '60%',
            maxHeight: 'unset',
            zIndex: 10000

        },
        paper: {
            boxShadow: 'rgba(58, 53, 65, 0.4) 0px 2px 10px 0px'
        },
        icon: {
            width: 24,
            height: 24,
        },
        icon_grp: {
            fontSize: '1.4rem',
            textShadow: '0 6px 6px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.2)'
        }
    }),
);

function TabPanel(props: TabPanelProps) {
    const { children, value, index, items, ...other } = props;
    const classes = useStyles();

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        props.onClose(event);
    };

    return (
        <div style={{ padding: '16px 32px' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid container spacing={0}>
                    {items.map(item => (
                        <Grid item xs={12} sm={6} md={4} key={item.Id} >
                            <Button
                                sx={{ p: '4px' }}
                                color='inherit'
                                component={NavLink}
                                to={`/catalog/${item.Name}`}
                                onClick={handleClose}
                                startIcon={<span className={clsx(classes.icon_grp, `mdi ${item.Img === '' ? 'mdi-shimmer' : item.Img.toLowerCase()}`)}></span>}
                            >
                                {item.Name}
                            </Button>
                        </Grid>
                    ))}

                </Grid>
            )}
        </div>
    );
}

function Categories({ categories }: Props): ReactElement {

    const classes = useStyles();
    // const theme = useTheme();
    // console.log('categories', categories);
    const width = useWidth();
    const history = useHistory();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const handleToggle = () => {
        setOpen((prevOpen) => true);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        const category = categories[newValue];

        if (category && category.Children.length === 0) {
            history.push(`/catalog/${category.Name}`);
        }
    };

    return (
        <div className={classes.root}>
            <Divider />
            {categories && categories.length > 0 &&
                <React.Fragment>
                    <Tabs
                        value={value}
                        onChange={handleChangeTab}
                        onClick={handleToggle}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable_auto_tabs"
                        ref={anchorRef}
                    >

                        {categories.map(item => (
                            <Tab className={classes.category}
                                key={item.Id}
                                label={(width === 'xs' ? null : item.Name)}
                                icon={<span className={clsx(classes.icon_grp, `mdi ${item.Img === '' ? 'link' : item.Img}`)}></span>}
                            />
                        ))}
                    </Tabs>

                    <Popper open={open} className={classes.popoverPaper}
                        anchorEl={anchorRef.current}
                        role={undefined} transition nonce={undefined} onResize={undefined} onResizeCapture={undefined} >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper square={true} elevation={0} className={classes.paper} >
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <div style={{ position: 'relative' }}>
                                            {categories[value].Children.length > 0 &&
                                                <IconButton
                                                    sx={{ position: 'absolute', top: 8, right: 16 }}
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <CancelOutlinedIcon />
                                                </IconButton>
                                            }

                                            {categories.map((item, idx) =>
                                                item.Children.length > 0 &&
                                                <TabPanel key={item.Id} value={value} index={idx} items={item.Children} onClose={handleClose}></TabPanel>
                                            )}
                                        </div>

                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </React.Fragment>
            }
        </div>

    );
}

export default Categories;