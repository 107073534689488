import { ReactElement, useContext, useState, useEffect, useCallback } from 'react';
import { Theme, useTheme, styled } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useAuth } from 'oidc-react';
import { NavLink, useHistory } from 'react-router-dom';

import ukLocale from 'date-fns/locale/uk';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { AppContext } from '../AppContext/AppContext';
import { Link, Collapse, Chip, Grid, Box, Card, TextField, Typography, Button, IconButton, CardContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

import ClearIcon from '@mui/icons-material/Clear';

import { format, add, intervalToDuration } from 'date-fns';
import { ICustomer } from '../../interfaces/ICustomer';
import { IsAdmin } from '../../helpers/utils';
import { IBalance } from '../../interfaces/reports/balance/IBalance';
import { IOrder } from '../../interfaces/IOrder';
import SaveIcon from '@mui/icons-material/Save';


const host = process.env.REACT_APP_IMG_ROOT;

const localeMap = {
    uk: ukLocale,
    en: enLocale,
    ru: ruLocale,
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: theme.spacing(1 / 2),
            padding: theme.spacing(1),
        },
        subtotal: {
            fontWeight: 'bold'
        }
    })
);

const startDayMonth = () => {
    const now = new Date();
    const yN = now.getFullYear();
    const mN = now.getMonth();
    // const dN = now.getDate();
    return new Date(yN, mN, 1);
};

const lastDayMonth = () => {
    const now = new Date();
    const yN = now.getFullYear();
    const mN = now.getMonth();
    return new Date(yN, mN + 1, 0);
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

type Props = {}

export const Balance = (props: Props) => {
    const classes = useStyles();
    const auth = useAuth();
    const theme = useTheme();
    const history = useHistory();
    const { contact } = useContext(AppContext);
    const [balance, setBalance] = useState<IBalance>();
    const [locale, setLocale] = useState<keyof typeof localeMap>('uk');

    const [isAdmin, setIsAdmin] = useState(false);
    const [filter, setFilter] = useState(false);
    const [startDay, SetStartDay] = useState<Date>(startDayMonth());
    const [lastDay, SetLastDay] = useState<Date>(lastDayMonth());
    const [customerFilter, setCustomerFilter] = useState<ICustomer | undefined>(contact?.Customer);
    const [invoice, setInvoice] = useState<IOrder | null>(null);
    const [open, setOpen] = useState(false);

    const handleDefaultPeriod = () => {
        SetStartDay(startDayMonth());
        SetLastDay(lastDayMonth());
    };

    const handleCustomerFilter = () => {
        if (contact) {
            setCustomerFilter(contact.Customer);
        }
    };

    const handleClearCustomerFilter = () => {
        setCustomerFilter(undefined);
    };

    const showDetailsInvoice = (id: string) => {
        fetchInvoce(id);
    };


    const exportToExcell = useCallback(async (id: string) => {
        if (!auth.userData) {
            return;
        }

        const url = `/api/v1.0/invoice/export/${id}`;
        const token = `Bearer ${auth.userData.access_token}`;
        let filename = '';

        await fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((result) => {
                if (!result.ok) {
                    throw Error(result.statusText);
                }

                // We are reading the *Content-Disposition* header for getting the original filename given from the server
                const header = result.headers.get('Content-Disposition');
                const parts = header?.split(';');
                debugger;
                if (parts) {
                    filename = parts[1].split('=')[1].replaceAll('\"', '');
                }
                return result.blob();
            })
            .then((blob) => {
                if (blob !== null) {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
            })
            .catch((err) => {
                console.log(err);
            });
        // .then((response) => response.blob())
        // .then(blob => {
        //     var file = window.URL.createObjectURL(blob);
        //     window.location.assign(file);
        // })
        // .catch((error) => {
        //     console.log('Error', error);
        // });

    }, [auth.userData]);

    const handleExportToExcell = (id: string) => {
        exportToExcell(id);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const fetchInvoce = useCallback(async (id: string) => {
        if (!auth.userData) {
            return;
        }

        const url = `/api/v1.0/invoice/load/${id}`;
        const token = `Bearer ${auth.userData.access_token}`;
        setInvoice(null);
        await fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setInvoice(data as IOrder);
                    console.log('Invoice', data);
                    setOpen(true);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            });

    }, [auth.userData]);

    const fetchData = useCallback(async (token: string, start: Date, end: Date, ClientID: string | undefined) => {
        const Completed: boolean = true;

        const url = `/api/v1.0/balance?DateStart=${format(start, 'yyyy-MM-dd')}&DateEnd=${format(end, 'yyyy-MM-dd')}${ClientID ? `&ClientID=${ClientID}` : ''}`;

        await fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
            .then((data) => {
                if (data as IBalance) {
                    console.log('Balance', data);
                    setBalance(data);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            });
    }, []);

    useEffect(() => {
        if (contact?.Customer) {
            setCustomerFilter(contact?.Customer);
        }

    }, [contact?.Customer]);

    useEffect(() => {
        if (!auth.userData) {
            return;
        }

        if (auth.userData) {
            const roles = auth.userData.profile.role as string[];
            const isAdmin = IsAdmin(roles, 'Administrator');
            setIsAdmin(isAdmin);
        }

        let ClientID;
        if (customerFilter) {
            ClientID = customerFilter.Id;
        }

        fetchData(`Bearer ${auth.userData.access_token}`, startDay, lastDay, ClientID);

    }, [auth.userData, fetchData, startDay, lastDay, customerFilter]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale]} >
            <div className={classes.root}>
                <Card className='it-box'>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item >
                                <Typography variant='h5' sx={{ pb: 2 }}>Історія розрахунків</Typography>
                            </Grid>
                            <Grid item >
                                <Chip
                                    sx={{ mt: 1 * 0.75 }}
                                    label={`Період: ${format(startDay, 'dd.MM.yyyy')} - ${format(lastDay, 'dd.MM.yyyy')}`}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => setFilter(!filter)}
                                    onDelete={handleDefaultPeriod}
                                />
                                {/* <IconButton onClick={() => setFilter(!filter)} >
                                    <FilterAltOutlinedIcon />
                                </IconButton> */}
                            </Grid>
                        </Grid>
                        <Collapse in={filter} timeout="auto">
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={2}>
                                    <DesktopDatePicker
                                        label="Початок періоду"
                                        inputFormat="dd.MM.yyyy"
                                        value={startDay}
                                        onChange={(newValue) => SetStartDay(newValue as Date)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <DesktopDatePicker
                                        label="Кінець періоду"
                                        inputFormat="dd.MM.yyyy"
                                        value={lastDay}
                                        onChange={(newValue) => SetLastDay(newValue as Date)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                {isAdmin &&
                                    <Grid item xs={6} sm={4}>
                                        <Button variant='outlined' onClick={handleCustomerFilter} sx={{ mr: 1 }}>Лише мої</Button>
                                        <IconButton onClick={handleClearCustomerFilter} title="Усі замовлення">
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                        </Collapse>

                        {/* <Collapse in={!filter} timeout="auto" unmountOnExit >
                            <Grid container spacing={2}>                                
                                {isAdmin && customerFilter &&
                                    <Grid item>
                                        <Chip label={`Покупець: ${customerFilter ? customerFilter.Name : ''}`} size="small" variant="outlined" onDelete={handleClearCustomerFilter} />
                                    </Grid>
                                }
                            </Grid>
                        </Collapse> */}

                        <TableContainer >
                            <Table sx={{ mb: 1 }} >
                                <TableBody>
                                    {
                                        balance?.Rows.map((row, idx) => (

                                            <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell
                                                    className={row.TypeDoc === 'Не определено' ? classes.subtotal : ''}
                                                    component="th" scope="row">
                                                    <small>{row.CurrencyName}</small>
                                                </TableCell>

                                                <TableCell
                                                    className={row.TypeDoc === 'Не определено' ? classes.subtotal : ''}
                                                    colSpan={row.TypeDoc === 'Не определено' ? 2 : undefined}
                                                >
                                                    {row.TypeDoc === 'Не определено' ? row.ContractName : ''}
                                                </TableCell>

                                                {row.TypeDoc !== 'Не определено' &&
                                                    <TableCell>
                                                        {row.TypeDoc === 'Расходная накладная' ?
                                                            <Link
                                                                component="button"
                                                                variant="body2"
                                                                color="inherit"
                                                                onClick={() => { showDetailsInvoice(row.Id); }}
                                                            >
                                                                {row.View}
                                                            </Link> : row.View}

                                                    </TableCell>
                                                }

                                                <TableCell className={row.TypeDoc === 'Не определено' ? classes.subtotal : ''}>
                                                    {(Math.ceil(row.AmountStart * 100) / 100).toFixed(2)}
                                                </TableCell>
                                                <TableCell className={row.TypeDoc === 'Не определено' ? classes.subtotal : ''}>
                                                    {(Math.ceil(row.AmountIn * 100) / 100).toFixed(2)}
                                                </TableCell>
                                                <TableCell className={row.TypeDoc === 'Не определено' ? classes.subtotal : ''}>
                                                    {(Math.ceil(row.AmountOut * 100) / 100).toFixed(2)}
                                                </TableCell>
                                                <TableCell className={row.TypeDoc === 'Не определено' ? classes.subtotal : ''}>
                                                    {(Math.ceil(row.AmountEnd * 100) / 100).toFixed(2)}
                                                </TableCell>

                                                <TableCell>
                                                    {row.TypeDoc === 'Расходная накладная' &&
                                                        <Chip
                                                            icon={<DownloadOutlinedIcon />}
                                                            size='small'
                                                            label="XSLS"
                                                            variant="outlined"
                                                            onClick={() => { handleExportToExcell(row.Id); }}
                                                        />
                                                    }
                                                </TableCell>

                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
                <BootstrapDialog
                    fullWidth={true}
                    maxWidth='lg'
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {invoice && <Typography>{invoice.Number}</Typography>}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        {invoice &&
                            <TableContainer >
                                <Table sx={{ mb: 1, width: '100%' }} >
                                    <TableBody>
                                        {
                                            invoice.Rows.map((row, idx) => (<TableRow key={idx}>
                                                <TableCell component="th" scope="row">
                                                    {row.ProductImage !== '' &&
                                                        <Link
                                                            color="inherit"
                                                            component="button"
                                                            onClick={() => history.push(`/catalog/item/${row.ProductCode}`)
                                                            }
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                style={{ width: 'auto', height: 32 }}
                                                                src={`${host}assets/files/images/prods/${row.ProductImage}`}
                                                                alt={row.ProductCode}
                                                            />
                                                        </Link>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {row.ProductCode}
                                                </TableCell>
                                                <TableCell>
                                                    {row.ProductArticle}
                                                </TableCell>
                                                <TableCell>
                                                    {row.ProductName}
                                                </TableCell>
                                                <TableCell>
                                                    {row.Qty}
                                                </TableCell>
                                                <TableCell>{(Math.ceil(row.SalePrice * 100) / 100).toFixed(2)}</TableCell>
                                                <TableCell>{(Math.ceil(row.Total * 100) / 100).toFixed(2)}</TableCell>
                                            </TableRow>))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </DialogContent>
                    {/* <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            Закрити
                        </Button>
                    </DialogActions> */}
                </BootstrapDialog>


            </div>
        </LocalizationProvider>
    );
};
