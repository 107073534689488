
import React, { createContext, useState, ReactNode, FC, useEffect } from 'react';
import { IOrder, IOrderMessage, IOrderRow } from '../../interfaces/IOrder';
import { useSnackbar } from 'notistack';
import { useAuth } from 'oidc-react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { IContact } from '../../interfaces/IContact';
import { IContract } from '../../interfaces/IContract';
import { IItem } from '../../interfaces/IItem';
import { emptyId } from '../../helpers/Constants';
import { ISettingCatalog } from '../../interfaces/ISettingCatalog';

const allwaysSaveInvoice = process.env.REACT_APP_ALLWAYS_SAVE_INVOICE;

type Props = { children: ReactNode }

export type AppContextState = {
    isDarkTheme: boolean;
    setDarkMode: (isDark: boolean) => void;
    // const [connection, setConnection] = useState<HubConnection>();
    hubConnection: HubConnection | null;
    sendOrderMess: (user: string, message: string) => void;

    order: IOrder;
    createOrder: () => void;
    confirmOrder: () => void;
    cancelOrder: (id: string) => void;
    clearOrder: () => void;

    addRow: (item: IItem, qty: number) => void;
    updRow: (idx: number, qty: number) => void;
    delRow: (lineNumber: number) => void;

    changeContract: (newContract: IContract) => void;
    populateOrder: (newBasket: IOrder) => void;

    contact: IContact | null,
    populateContact: (contact: IContact | null) => void;

    contract: IContract | null,
    populateContract: (contract: IContract | null) => void;

    settings: ISettingCatalog;
    changeSetting: (newSetting: ISettingCatalog) => void;
};

const contextDefaultValues: AppContextState = {
    isDarkTheme: false,
    setDarkMode: () => { },

    hubConnection: null,
    sendOrderMess: () => { },

    order: {
        Id: emptyId,
        Number: '',
        Date: new Date(),
        Completed: false,
        Confirmed: false,
        Customer: null,
        Contract: null,
        OrderTotal: 0,
        Discount: 0,
        CurrencyName: '',
        ContractName: '',
        Comment: '',
        Reserve: 0,
        RelatedDocs: 0,
        Rows: []
    },

    createOrder: () => { },
    confirmOrder: () => { },
    cancelOrder: () => { },
    clearOrder: () => { },

    addRow: () => { },
    updRow: () => { },
    delRow: () => { },
    changeContract: () => { },
    populateOrder: () => { },

    contact: null,
    populateContact: () => { },

    contract: null,
    populateContract: () => { },

    settings: { direction: 'desc' },
    changeSetting: () => { },

};

const AppContext = createContext<AppContextState>(contextDefaultValues);

const AppProvider: FC<Props> = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();
    const auth = useAuth();

    let isDark: boolean = false;
    const appTheme = localStorage.getItem('app-theme');
    if (typeof (appTheme) === 'undefined') {
        isDark = contextDefaultValues.isDarkTheme;
    } else {
        isDark = appTheme === 'dark';
    }

    let currentOrder: IOrder;
    const currentJson: string | null = localStorage.getItem('currentOrder');

    if (!currentJson) {
        currentOrder = contextDefaultValues.order;
    } else {
        currentOrder = JSON.parse(currentJson) as IOrder;
    }

    const [hubConnection, setHubConnection] = useState<HubConnection | null>(contextDefaultValues.hubConnection);
    const [order, setOrder] = useState<IOrder>(currentOrder);
    const [contact, setContact] = useState<IContact | null>(contextDefaultValues.contact);
    const [contract, setContract] = useState<IContract | null>(contextDefaultValues.contract);
    const [isDarkTheme, setIsDarkTheme] = useState<boolean>(isDark);
    const [settings, setSettings] = useState<ISettingCatalog>(contextDefaultValues.settings);

    const sendOrderMess = async (user: string, message: string) => {
        if (!hubConnection) {
            return;
        }
        await hubConnection.invoke('SendMessage', user, message);
    };

    const recalculOrder = (order: IOrder) => {
        const _order = { ...order };
        _order.OrderTotal = 0;

        for (let i = 0; i < _order.Rows.length; i++) {
            const row = _order.Rows[i];
            row.LineNumber = i + 1;
            _order.OrderTotal += row.Total;
        }
        localStorage.setItem('currentOrder', JSON.stringify(_order));
        setOrder(_order);
        return _order;
    };

    const createOrder = () => {
        const newOrder = { ...contextDefaultValues.order };
        newOrder.Contract = contract;
        setOrder(newOrder);
        localStorage.setItem('currentOrder', JSON.stringify(newOrder));

        // if (auth.userData?.profile.email) {
        //     const message: IOrderMessage = {
        //         action: 'create_order',
        //         order: newOrder,
        //         s_hash: auth.userData.profile.s_hash
        //     };

        //     sendUpdOrderMess(auth.userData.profile.email, JSON.stringify(message));
        // }
    };

    const confirmOrder = () => {
        console.log('Confirm current order');
        if (auth.userData?.profile.email) {
            const message: IOrderMessage = {
                action: 'confirm_order',
                order: order,
                s_hash: auth.userData.profile.s_hash
            };

            sendOrderMess(auth.userData.profile.email, JSON.stringify(message));
        }
    };

    const cancelOrder = (id: string) => {
        console.log('Cancel selected order, id:', id);
    };

    const clearOrder = () => {
        order.Rows.length = 0;
        setOrder(recalculOrder(order));
        if (auth.userData?.profile.email) {
            const message: IOrderMessage = {
                action: 'clear_order_rows',
                order: order,
                s_hash: auth.userData.profile.s_hash
            };

            sendOrderMess(auth.userData.profile.email, JSON.stringify(message));
        }

    };

    const addRow = (item: IItem, qty: number = 1) => setOrder((order) => {
        const exist = order.Rows.find(r => r.ProducId === item.Id);
        if (!order.Contract) {
            order.Contract = contract;
        }

        if (order.Customer === null && contact) {
            order.Customer = contact.Customer;
        }

        const price = item.Prices.find(p => p.Id === order.Contract?.TypePrice.Id);

        if (price) {
            if (!exist) {
                const row: IOrderRow = {
                    Id: '',
                    LineNumber: 0,
                    Price: price,
                    SalePrice: price.Value,
                    Qty: qty,
                    Total: qty * price.Value,
                    ProducId: item.Id,
                    ProductName: item.Name,
                    ProductCode: item.Code,
                    ProductArticle: item.Article,
                    ProductImage: item.Image,
                    CategoryImage: ''
                };

                row.LineNumber = order.Rows.length + 1;
                order.Rows.push(row);

            } else {
                exist.Qty += qty;
                exist.Price = price;
                exist.SalePrice = price.Value;
                exist.Total = exist.Qty * exist.SalePrice;
            }

            enqueueSnackbar(
                `Добавлено: ${item.Name}`,
                {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'left' }
                }
            );
        }

        const recalc = recalculOrder(order);

        if (auth.userData?.profile.email) {
            const message: IOrderMessage = {
                action: 'add_order_row',
                order: recalc,
                s_hash: auth.userData.profile.s_hash
            };

            sendOrderMess(auth.userData.profile.email, JSON.stringify(message));
        }
        return recalc;
    });

    const updRow = (idx: number, qty: number) => setOrder((order) => {
        const exist = order.Rows.find(r => r.LineNumber === idx);

        if (exist) {
            exist.Qty = qty;
            exist.Total = exist.Qty * exist.SalePrice;
        }

        const recalc = recalculOrder(order);

        if (auth.userData?.profile.email) {
            const message: IOrderMessage = {
                action: 'update_order_row',
                order: recalc,
                s_hash: auth.userData.profile.s_hash
            };
            sendOrderMess(auth.userData.profile.email, JSON.stringify(message));
        }

        return recalc;
    });

    const delRow = (lineNumber: number) => setOrder((order) => {
        const idx = order.Rows.findIndex(r => r.LineNumber === lineNumber);
        if (idx > -1) {
            const msg = `Видалено: ${order.Rows[idx].ProductName}`;
            order.Rows.splice(idx, 1);
            enqueueSnackbar(
                msg,
                {
                    variant: 'warning',
                    anchorOrigin: { vertical: 'top', horizontal: 'left' }
                }
            );
        }

        const recalc = recalculOrder(order);

        if (auth.userData?.profile.email) {
            const message: IOrderMessage = {
                action: 'delete_order_row',
                order: recalc,
                s_hash: auth.userData.profile.s_hash
            };
            sendOrderMess(auth.userData.profile.email, JSON.stringify(message));
        }

        return recalc;
    });

    const changeContract = (newContract: IContract) => {
        order.Contract = newContract;
        return recalculOrder(order);
    };

    const populateOrder = (order: IOrder) => {
        localStorage.setItem('currentOrder', JSON.stringify(order));
        setOrder(order);
    };

    const populateContact = (newContact: IContact | null) => {
        setContact(newContact);
        if (order.Customer === null && newContact) {
            order.Customer = newContact.Customer;
            return recalculOrder(order);
        }
    };

    const populateContract = (newContract: IContract | null) => {
        setContract(newContract);

        if (order && order.Rows.length > 0 && (order.Contract?.Id !== newContract?.Id)) {
            enqueueSnackbar(
                'Договір в замовленні інший чим в каталозі! Католог тільки для перегляду.',
                {
                    variant: 'warning',
                    anchorOrigin: { vertical: 'top', horizontal: 'left' }
                }
            );
        }
        if (order.Contract === null) {
            order.Contract = newContract;
            return recalculOrder(order);
        }
    };

    const setDarkMode = (darkMode: boolean) => {
        setIsDarkTheme(darkMode);
        localStorage.setItem('app-theme', darkMode ? 'dark' : 'light');
    };

    const changeSetting = (newSetting: ISettingCatalog) => {
        setSettings(newSetting);
    };

    const onReciveMessage = (message: IOrderMessage) => {
        if (auth.userData?.profile && message.s_hash !== auth.userData.profile.s_hash) {
            const currentJson: string | null = localStorage.getItem('currentOrder');
            const currentOrder: IOrder | null = currentJson ? JSON.parse(currentJson) as IOrder : null;

            if (message.action === 'confirm_order') {
                // if (order.Id === message.order.Id) {
                createOrder();
                enqueueSnackbar(
                    `Замовлення № ${message.order.Number} підтвержено в іншій сесії`,
                    {
                        variant: 'success',
                        anchorOrigin: { vertical: 'top', horizontal: 'left' }
                    }
                );
                // }
            } else if (message.action === 'save_order') {
                populateOrder(message.order);
                enqueueSnackbar(
                    `Замовлення № ${message.order.Number} збережено в іншій сесії`,
                    {
                        variant: 'success',
                        anchorOrigin: { vertical: 'top', horizontal: 'left' }
                    }
                );
            } else {
                populateOrder(message.order);
                enqueueSnackbar(
                    'Замовлення змінено в іншій сесії',
                    {
                        variant: 'info',
                        anchorOrigin: { vertical: 'top', horizontal: 'left' }
                    }
                );
            }

            // if (currentJson) {                
            //     populateOrder(JSON.parse(currentJson) as IOrder);
            //     enqueueSnackbar(
            //         'Замовлення змінено в іншій сесії',
            //         {
            //             variant: 'info',
            //             anchorOrigin: { vertical: 'top', horizontal: 'left' }
            //         }
            //     );
            // }
        }
    };

    useEffect(() => {
        if (!auth || !auth.userData) {
            return;
        }

        const fetchData = async (token: string) => {
            const url = `/orderHub`;

            const con = new HubConnectionBuilder()
                .withUrl(url, { accessTokenFactory: () => token })
                .withAutomaticReconnect()
                .build();

            con.on('ReceiveMessage', (_user, _message) => {
                //console.log('ReceiveMessage', _user, _message, auth?.userData);
                const updMessage: IOrderMessage = JSON.parse(_message) as IOrderMessage;
                if (updMessage) {
                    onReciveMessage(updMessage);
                }
            });

            // con.on('ReceiveSystemMessage', (_user, _message) => {
            //     console.log('ReceiveSystemMessage', _message);
            // });

            await con.start();

            setHubConnection(con);
        };
        // console.log('USER DATA', auth?.userData);

        fetchData(`${auth?.userData?.token_type} ${auth?.userData?.access_token}`);

    }, [auth]);

    return (
        <AppContext.Provider
            value={{
                isDarkTheme, hubConnection,
                order, contact, contract, settings,
                sendOrderMess, createOrder, confirmOrder, cancelOrder, clearOrder,
                addRow, updRow, delRow, changeContract,
                populateOrder,
                populateContact, populateContract,
                setDarkMode, changeSetting
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider, AppContext };