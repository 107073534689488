import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { ukUA } from '@mui/material/locale';

const light = createTheme({
  palette: {
    mode: 'light',
    darkBlue: {
    },
    background: {
      default: 'rgb(244, 245, 250)'
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: '#000',
          backgroundColor: '#fff',
          boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px'
        }
      }
    }
  }
}, ukUA);

const dark = createTheme({
  palette: {
    mode: 'dark',
    darkBlue: {
    },
    background: {
      default: grey[900],
      paper: '#2a3438',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: '#fff',
          backgroundColor: '#121212',
          boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px'
        }
      }
    }
  },
}, ukUA);

export { light, dark };