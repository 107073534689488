
export const IsAdmin = (roles: string | string[], role: string) => {
    if (!roles) {
        return false;
    }

    if (Array.isArray(roles)) {
        const idx = roles.findIndex((x) => x === 'Administrator');
        return idx !== -1;
    } else {
        return roles === 'Administrator';
    }
};

export const utf8_to_b64 = (str: string) => {
    return window.btoa(unescape(encodeURIComponent(str)));
};

export const b64_to_utf8 = (str: string) => {
    return decodeURIComponent(escape(window.atob(str)));
};