import React, { useState, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { IOrder } from '../../interfaces/IOrder';
import { Link, Grid, Paper, Typography, useTheme, Box } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

const host = process.env.REACT_APP_IMG_ROOT;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: theme.spacing(1 / 2),
            padding: theme.spacing(1),
        },

        paper: {
            backgroundImage: 'none',
            padding: theme.spacing(2, 2),
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px'
        },

    })
);

interface IProps {
    order: IOrder
}


export const Thank = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const theme = useTheme();
    const [order, setOrder] = useState<IOrder>();

    useEffect(() => {

        if (location.state) {
            // console.log(location.state);
            const { ...details } = location.state;
            if (details) {
                // console.log((details as IProps).order);
                setOrder((details as IProps).order);
            }
        }

    }, [location]);

    return (
        <div className={classes.root}>
            <Paper sx={{ p: 2 }}>
                <Typography
                    variant='h4'
                    display='block'
                    gutterBottom
                    sx={{ fontWeight: 100 }}
                >
                    Дякуємо вам за замовлення
                </Typography>

                {order && <>
                    <Typography variant='h6' sx={{ fontWeight: 400 }} gutterBottom>
                        № {order.Number}, {order.Customer?.Name}
                    </Typography>

                    {/* Header table */}
                    <Grid
                        container
                        spacing={2}
                        alignItems='center'
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            py: 1,
                            borderBottom: theme.palette.mode === 'light' ? '1px solid #eee' : '1px solid #777',
                            fontWeight: 'bold', fontSize: '.9em'
                        }}
                    >
                        <Grid item sx={{ minWidth: 50 }}>№</Grid>
                        <Grid item sx={{ minWidth: 70 }}></Grid>
                        <Grid item xs={6} sm={2} md={1}>
                            <div><small>Код</small></div>
                            <div>Арт.</div>
                        </Grid>
                        <Grid item xs={12} sm={5}>Найменування</Grid>
                        <Grid item xs={2} sm={1} sx={{ textAlign: 'right' }}>К-сть</Grid>
                        <Grid item xs={2} sm={1} sx={{ textAlign: 'right' }}>Ціна</Grid>
                        <Grid item xs={3} sm={2} sx={{ textAlign: 'right' }}>Сума</Grid>
                        <Grid item></Grid>
                    </Grid>

                    {/* Details */}
                    {
                        order.Rows.map((row) => (
                            <Grid
                                key={row.LineNumber}
                                container
                                spacing={2}
                                alignItems='center'
                                sx={{ py: 1, borderBottom: theme.palette.mode === 'light' ? '1px solid #eee' : '1px solid #777' }}
                            >
                                <Grid item sx={{ minWidth: 50 }}>{row.LineNumber}</Grid>

                                <Grid item sx={{ minWidth: 70 }}>
                                    {row.ProductImage !== '' &&
                                        <Link
                                            color="inherit"
                                            component="button"
                                            onClick={() => history.push(`/catalog/item/${row.ProductCode}`)
                                            }
                                        >
                                            <img
                                                loading="lazy"
                                                style={{ width: 'auto', height: 32 }}
                                                src={`${host}assets/files/images/prods/${row.ProductImage}`}
                                                alt={row.ProductCode}
                                            />
                                        </Link>

                                    }
                                </Grid>

                                <Grid item xs={6} sm={2} md={1}>
                                    <Typography display='block' variant='caption'>{row.ProductCode}</Typography>
                                    <Typography display='block' variant='body2' >{row.ProductArticle}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={5}>
                                    <Typography variant='body2' >
                                        {row.ProductName}
                                    </Typography>
                                </Grid>

                                <Grid item xs={3} sm={1} sx={{ textAlign: 'right' }}>
                                    {row.Qty}
                                </Grid>
                                <Grid item xs={3} sm={1} sx={{ textAlign: 'right' }}>{(Math.ceil(row.SalePrice * 100) / 100).toFixed(2)}</Grid>
                                <Grid item xs={4} sm={2} sx={{ textAlign: 'right' }}>{(Math.ceil(row.Total * 100) / 100).toFixed(2)}</Grid>


                            </Grid>
                        ))
                    }
                    {/* Footer */}
                    <Grid
                        container
                        spacing={2}
                        alignItems='center'
                        sx={{
                            pt: 2
                        }}
                    >
                        <Grid item sx={{ minWidth: 120 }}>
                        </Grid>
                        <Grid item xs={7} sm={8} >
                            <Typography display='block' textAlign='right'>Проміжний підсумок:</Typography>
                            {/* <Typography display='block' textAlign='right' gutterBottom>Знижка:</Typography>
                                <Typography display='block' textAlign='right' gutterBottom>Доставка:</Typography> */}
                            <Typography
                                variant='h5'
                                display='block'
                                textAlign='right'
                                sx={{ fontWeight: 100, mt: 2 }}
                            >
                                Загалом:
                            </Typography>
                        </Grid>
                        <Grid item xs={5} sm={2}>

                            <Typography variant='subtitle2' display='block' textAlign='right' gutterBottom>{(Math.ceil(order.OrderTotal * 100) / 100).toFixed(2)}</Typography>
                            {/* <Typography display='block' textAlign='right' gutterBottom>{(Math.ceil(order.Discount * 100) / 100).toFixed(2)}</Typography>
                                    <Typography display='block' textAlign='right' gutterBottom>{(Math.ceil(order.Discount * 100) / 100).toFixed(2)}</Typography> */}
                            {/* <Divider sx={{ pb: 2 }} /> */}
                            <Typography
                                variant='h5'
                                display='block'
                                textAlign='right'
                                sx={{ mt: 2 }}
                            >
                                {(Math.ceil(order.OrderTotal * 100) / 100).toFixed(2)}
                            </Typography>

                        </Grid>

                    </Grid>

                </>
                }

            </Paper>


        </div>
    );
};
