import React, { ReactElement, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Slider from './Slider/Slider';
import { Card, CardMedia, CardContent, Box, Grid, Button, Typography, CircularProgress, LinearProgress, Alert, Paper } from '@mui/material';

import { WgCatalog } from './Widgets/WgCatalog';
import { WgOrders } from './Widgets/WgOrders';
import { WgBalance } from './Widgets/WgBalance';
import { WgUploadOrder } from './Widgets/WgUploadOrder';

import { useAuth } from 'oidc-react';
import { green } from '@mui/material/colors';
import { AppContext } from './AppContext/AppContext';

// import theme from '../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingBottom: theme.spacing(8),
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(6),
      },
    },
    box: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2, 1),
    },
    paperBox: {
      padding: theme.spacing(1, 2),
    },
    progress: {
      height: 2,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    slider: {
      minHeight: 416,
      backgroundColor: theme.palette.primary.main
    },
    file: {
    },
    button: {
      margin: theme.spacing(0, 1),
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    card: {
      borderRadius: 0,
      backgroundColor: '#a2d8ff',
      backgroundImage: 'url("/assets/files/images/hp/time.jpg?v=1.001")',
      backgroundSize: 'cover',
      // 'webapp/wwwroot/assets/files/images/hp/tree-cone-cube-bg-light.png'
      // color: 'rgba(58, 53, 65, 0.87)',
      // transition: 'box - shadow 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px'
    },
    headline: {
      color: 'white',
      textShadow: '0 4px 4px rgb(0 0 0 / 11%), 0 6px 13px rgb(0 0 0 / 9%)',
      position: 'absolute',
      top: theme.spacing(1),
      right: 0,
      backgroundColor: '#5399ccab',
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        top: theme.spacing(1),
        left: 0,
        right: '0',
      },
    },
    ln1: {
      fontWeight: 100,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.8rem'
      },
    },
    ln2: {
      fontWeight: 100,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem'
      },
    },
    wg_box: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: theme.spacing(-12),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(-6),
      },
    }

  })
);

interface IRow {
  Code: string;
  Qty: number;
  State: string;
}

interface IOrder {
  DocNum: string;
  Date: Date;
  Error: string;
  Rows: IRow[];
}

function Home(): ReactElement {
  const [showProgress, setShowProgress] = useState(false);
  const { contact } = useContext(AppContext);
  const classes = useStyles();
  const [data, setData] = useState({ slides: [] });
  const [file, setFile] = useState<File | null>();
  const [order, setOrder] = useState<IOrder | null>();

  const authority = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const url = '/api/v1.0/Slider/hp-slider.json';
      await fetch(url, { headers: { accept: 'Accept: application/json' } })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setData({ slides: data });
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });

    };
    fetchData();
  }, []);

  const fileCatalog = () => {
    const date = new Date();
    const ds = date.getFullYear()
      + '-' + ('0' + (date.getMonth() + 1)).slice(-2)
      + '-' + ('0' + date.getDate()).slice(-2)
      + ' ' + ('0' + date.getHours()).slice(-2)
      + ':' + ('0' + date.getMinutes()).slice(-2);

    return `catalog_${ds}.xml`;
  };

  const downloadFile = () => {
    setShowProgress(true);

    const token = (authority && authority.userData) ? (`Bearer ${authority.userData.access_token}`) : '';

    const url = '/api/v1.0/Price';
    // console.log("download file from:", url);
    // console.log("token", token);    
    fetch(url,
      {
        headers: {
          authorization: token, accept: 'Accept: application/json',
          responseType: 'blob',
        },

      })
      .then((response) => response.blob())
      .then((data) => {
        if (data) {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          const filename = fileCatalog();
          link.href = downloadUrl;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setShowProgress(false);
        }
      })
      .catch((error) => {
        console.log('Error', error);
      });
  };

  const onClickFileUpload = () => {
    if (file) {
      uploadFile(file);
    }
  };

  const uploadFile = (file: any) => {
    const formData = new FormData();
    formData.append('file', file);

    const token = (authority && authority.userData) ? (`Bearer ${authority.userData.access_token}`) : '';
    const method = 'POST';
    const url = '/api/v1.0/Order/Upload';

    axios
      .request({
        url,
        method,
        data: formData,
        headers: { Authorization: token },
      })
      .then(({ data }) => {
        setOrder(data.Order);
        setShowProgress(false);
        console.log('Data', data.Order);
      });
  };

  return (
    <div className={classes.root}>
      {showProgress &&
        <LinearProgress className={classes.progress} color="secondary" />
      }
      <Box>
        <Card className={classes.card}>
          <CardContent>
            <Box sx={{ pt: authority.userData ? 27 : 32, pb: authority.userData ? 27 : 32, position: 'relative' }}>
              <Box className={classes.headline}>
                <Typography className={classes.ln1} variant='h5'>
                  {contact ? <> <Typography variant='body1'>Раді вам знову.</Typography>Вітаємо. {contact.Customer.Name}</> : 'Раді, що завітали до нас'}</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {authority.userData &&
        <Box className={classes.wg_box} >
          <Grid container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={2} >
            <Grid item xs={12} sm={4} md={3}><WgCatalog /></Grid>
            <Grid item xs={12} sm={4} md={3}><WgUploadOrder /></Grid>
            <Grid item xs={12} sm={4} md={3}><WgOrders /></Grid>
            <Grid item xs={12} sm={4} md={3}><WgBalance /></Grid>
          </Grid>
        </Box>
      }

    </div>
  );
}

export default Home;
