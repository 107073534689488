import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from 'oidc-react';
import { AppContext } from '../AppContext/AppContext';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Button, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

type Props = {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: theme.spacing(1 / 2),
            padding: theme.spacing(1),
        },
    })
);

export const Payments = (props: Props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Card className='it-box'>
                <CardContent>
                    <Typography variant='h5'>Історія оплат</Typography>
                </CardContent>
            </Card>
        </div>
    );
};
