import React, { useState } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from 'oidc-react';
import { AppContext } from '../AppContext/AppContext';
import { CircularProgress, Fab, Grid, Badge, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Snackbar, Button, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { green } from '@mui/material/colors';

import GetAppIcon from '@mui/icons-material/GetApp';

type Props = {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },

        media: {
            height: 160,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        },
        content: {
            height: 130,
        },
        cat_img: {
            fontSize: 130,
            color: '#ccc',
            textShadow: '0 28px 28px rgba(0,0,0,0.08), 0 10px 10px rgba(0,0,0,0.08)',
        },
        act_btn: {
            minWidth: 160
        },
        wrapper: {
            position: 'relative',
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    })
);

const fileCatalog = () => {
    const date = new Date();
    const ds = date.getFullYear()
        + '-' + ('0' + (date.getMonth() + 1)).slice(-2)
        + '-' + ('0' + date.getDate()).slice(-2)
        + ' ' + ('0' + date.getHours()).slice(-2)
        + ':' + ('0' + date.getMinutes()).slice(-2);
    return `catalog_${ds}.xml`;
};

export const WgCatalog = (props: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const auth = useAuth();
    const [showProgress, setShowProgress] = useState(false);

    const downloadFile = () => {
        setShowProgress(true);

        const token = (auth && auth.userData) ? (`Bearer ${auth.userData.access_token}`) : '';

        const url = '/api/v1.0/Price';
        // console.log("download file from:", url);
        // console.log("token", token);    
        fetch(url,
            {
                headers: {
                    authorization: token, accept: 'Accept: application/json',
                    responseType: 'blob',
                },

            })
            .then((response) => response.blob())
            .then((data) => {
                if (data) {
                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    const filename = fileCatalog();
                    link.href = downloadUrl;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setShowProgress(false);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            });
    };

    return (
        <Card className='it-box' elevation={0}>
            <CardContent>
                <img className='wg-img' src="/assets/files/images/hp/catalog.png" alt="catalog" />
                <Typography variant='h5'>It-Link прайс-лист</Typography>
                <Typography variant='body2' sx={{ mb: 8 }}>Current products list, format xml(yaml) </Typography>
                <div className={classes.wrapper}>
                    <Button
                        className={classes.act_btn}
                        variant="contained"
                        color="secondary"
                        startIcon={<GetAppIcon />}
                        size="small"
                        onClick={downloadFile}
                        disabled={showProgress}
                    >
                        Скачати
                    </Button>
                    {showProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </CardContent>
        </Card>
    );
};
