import React, { useContext } from 'react';

import { Route, Redirect, Switch } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';

import Catalog from './components/Catalog/Catalog';
import Search from './components/Catalog/Search';
import { Orders, Order, Payments, Balance, Thank, ClearCache } from './components/Docs';



import Item from './components/Catalog/Item';
import NotFound from './components/NotFound/NotFound';
import './custom.css';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import { AppProvider } from './components/AppContext';
import { compose } from 'redux';


const { REACT_APP_AUTHORIRY, REACT_APP_CLIENT_ID, REACT_APP_REDIRECT_URL,
  REACT_APP_SILENT_REDIRECT_URL, REACT_APP_POST_LOGOUT_REDIRECT_URL,
  REACT_APP_RESPONSE_TYPE, REACT_APP_SCOPE } = process.env;


const oidcConfig: AuthProviderProps = {
  // onSignIn: () => {
  //   // Redirect?    
  // },
  // onSignIn: async (user: any) => {
  //   // alert('You just signed in, congratz! Check out the console!');
  //   // console.log(user);
  //   // window.location.hash = '';
  // },
  authority: REACT_APP_AUTHORIRY,
  clientId: REACT_APP_CLIENT_ID,
  redirectUri: REACT_APP_REDIRECT_URL,
  silentRedirectUri: REACT_APP_SILENT_REDIRECT_URL,
  postLogoutRedirectUri: REACT_APP_POST_LOGOUT_REDIRECT_URL,
  responseType: REACT_APP_RESPONSE_TYPE,
  scope: REACT_APP_SCOPE,
  loadUserInfo: true,
  autoSignIn: false,
  automaticSilentRenew: true,

};

const App = () => {
  return (
    <AuthProvider {...oidcConfig} >
      <AppProvider>
        <Layout>
          <Switch>
            <Route exact path='/'><Home /></Route>
            <Route exact path="/catalog/:id" component={Catalog} />
            <Route exact path="/search/:search" component={Search} />
            <Route exact path="/catalog/item/:id" component={Item} />
            <Route exact path="/orders" component={Orders} />
            <Route exact path="/order/:id?" component={Order} />
            <Route exact path='/thank' component={Thank} />
            <Route exact path="/payments" component={Payments} />
            <Route exact path="/balance" component={Balance} />

            <Route exact path='/clearcache' component={ClearCache} />
            <Route exact path="/notfound" component={NotFound} status={404} />
            <Route path="*" component={NotFound} status={404} />
            <Redirect to="/" />
          </Switch>
        </Layout>
      </AppProvider>
    </AuthProvider>
  );
};

export default App;