import React, { useContext } from 'react';
import { Theme, ThemeProvider } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import HeaderAppBar from './AppNavigator/AppNavigator';
import Footer from './Footer/Footer';
import { AppContext } from './AppContext/AppContext';
import { dark, light } from '../Theme/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      dispaly: 'flex'
    }
  })
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ScrollTop(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 12, right: 12 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

export default function Layout(props: { children?: React.ReactNode }) {
  const classes = useStyles();
  const { isDarkTheme } = useContext(AppContext);

  return (
    <ThemeProvider theme={isDarkTheme ? dark : light} >
      <CssBaseline />
      <div className={classes.root}>
        <HeaderAppBar />
        <>{props.children}</>
        <Footer />
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top" sx={{ opacity: '0.7' }}>
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </div>
    </ThemeProvider>
  );
}
