import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from 'oidc-react';
import { AppContext } from '../AppContext/AppContext';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Button, IconButton } from '@mui/material';
import { useHistory, NavLink } from 'react-router-dom';

import clsx from 'clsx';

import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

type Props = {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },

        media: {
            height: 160,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        },
        content: {
            height: 130,
        },
        act_btn: {
            minWidth: 160
        },
        cat_img: {
            fontSize: 130,
            color: '#ccc',
            textShadow: '0 28px 28px rgba(0,0,0,0.08), 0 10px 10px rgba(0,0,0,0.08)',
        },

    })
);

export const WgOrders = (props: Props) => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <Card className='it-box'>
            <CardContent>
                <img className='wg-img' src="/assets/files/images/hp/orders.png" alt="catalog" />
                <Typography variant='h5'>Історія замовлень</Typography>
                <Typography variant='body2' sx={{ mb: 8 }}>History orders</Typography>

                <Button
                    component={NavLink} to='/orders'
                    className={classes.act_btn}
                    variant="contained"
                    color="warning"
                    startIcon={<ManageHistoryIcon />}
                    size="small"
                >
                    Замовлення
                </Button>

            </CardContent>
        </Card>
    );
};
