import React, { useContext } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from 'oidc-react';
import { AppContext } from '../AppContext/AppContext';
import { Fab, Grid, Badge, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Snackbar, Button, IconButton } from '@mui/material';
import { useHistory, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import HistoryIcon from '@mui/icons-material/History';

type Props = {}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },

        media: {
            height: 160,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        },
        content: {
            height: 130,
        },
        act_btn: {
            minWidth: 160
        },
        cat_img: {
            fontSize: 130,
            color: '#ccc',
            textShadow: '0 28px 28px rgba(0,0,0,0.08), 0 10px 10px rgba(0,0,0,0.08)',
        }
    })
);

export const WgBalance = (props: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const { contact } = useContext(AppContext);

    return (
        <Card className='it-box' elevation={0}>
            <CardContent>
                <img className='wg-img' src="/assets/files/images/hp/payments.png" alt="catalog" />
                <Typography variant='h5'>Історія розрахунків</Typography>
                <Typography variant='body2' sx={{ mb: 8 }}>
                    покупець: {contact && <b>{contact.Customer.Name}</b>}
                </Typography>
                <Button
                    component={NavLink}
                    className={classes.act_btn}
                    variant='contained'
                    color="success"
                    startIcon={<HistoryIcon />}
                    size="small"
                    to="/balance"
                >
                    Розрахунки
                </Button>
            </CardContent>
        </Card>
    );
};