import { ReactElement, useState, useEffect, useContext } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from 'oidc-react';
import clsx from 'clsx';
import { LinearProgress, Grid, Paper, Typography } from '@mui/material';
import { ICatalog } from '../../interfaces/ICatalog';
import { ProductThum } from './ProductThum';
import { AppContext } from '../AppContext';
import { IsAdmin } from '../../helpers/utils';

const CatalogEmpty = {
    Category: {
        Id: '',
        ParentId: '',
        IsFolder: false,
        Img: '',
        Code: '',
        Name: '',
        NameEng: '',
        Children: []
    },
    CategoryProperties: [],
    Contact: {
        Id: '',
        Name: '',
        FirstName: '',
        LastName: '',
        MiddleName: '',
        Email: '',
        Phone: '',
        Customer: {
            Id: '',
            Name: '',
            TypePrice: {
                Id: '',
                Name: '',
                NameSite: ''
            },
            Contracts: []
        }
    },
    Items: []
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
        },
        header: {
            padding: theme.spacing(1),
            marginBottom: theme.spacing(2),
            fontWeight: 100,
            transition: 'box - shadow 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
            boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px'
        },
        progress: {
            height: 2,
        },
        prices: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        groupIcon: {
            fontSize: '2.125rem',
            lineHeight: '1.235',
            color: 'primary',
            textShadow: '0 28px 28px rgba(0,0,0,0.08), 0 10px 10px rgba(0,0,0,0.08)',
        }
    })
);

export default function Search(props: any): ReactElement {
    const classes = useStyles();
    const auth = useAuth();
    const { contract } = useContext(AppContext);

    const [showProgress, setShowProgress] = useState(false);
    const [catalog, setCatalog] = useState<ICatalog>(CatalogEmpty);
    const [showRest, setShowRest] = useState(false);

    useEffect(() => {
        const fetchData = async (search: string, token: string, contractId: string) => {
            setShowProgress(true);
            const url = `/api/v1.0/catalog/search/${search}/${contractId}`;
            setCatalog(CatalogEmpty);

            await fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        setCatalog(data);
                        setShowProgress(false);
                    }
                })
                .catch((error) => {
                    console.log('Error', error);
                });
        };

        if (auth.userData && contract) {

            const roles = auth.userData.profile.role as string[];
            const isAdmin = IsAdmin(roles, 'Administrator');
            setShowRest(isAdmin);

            fetchData(props.match.params.search, `${auth.userData.token_type} ${auth.userData.access_token}`, contract.Id);
        }
        else {
            setCatalog(CatalogEmpty);
        }
    }, [props.match.params.search, auth.userData, contract]);


    return (
        <div className={classes.root}>
            {showProgress &&
                <LinearProgress className={classes.progress} color="secondary" />
            }

            {catalog && !showProgress &&
                <Paper className={classes.header}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                style={{ fontWeight: 100 }}>
                                <small>Результат пошуку:</small> {props.match.params.search} <small>[{catalog.Items.length}]</small>
                            </Typography>
                            {!auth.userData &&
                                <Typography variant="h4" gutterBottom align="center" color="error">
                                    Access only to authorized users!
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            }

            {
                catalog &&
                <Grid container direction="row" spacing={2} >
                    {catalog.Items.map(item => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={item.Id} >
                            <ProductThum
                                item={item}
                                customerPriceType={catalog.Contact.Customer.TypePrice}
                                categoryImg={''}
                                showRest={showRest}
                            />
                        </Grid>
                    ))}
                </Grid>
            }
        </div>
    );
}
