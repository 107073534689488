import React, { ReactElement, useEffect, useState, useContext } from 'react';
import { useAuth } from 'oidc-react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Fab, Typography, Button, Divider, Grid, Paper } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { NavLink } from 'react-router-dom';
import ProductSlider from './ProductSlider';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { IItem } from '../../interfaces/IItem';
import { ICategory } from '../../interfaces/ICategory';
import { IContact } from '../../interfaces/IContact';
import { AppContext } from '../AppContext/AppContext';
import RemoveShoppingCartOutlinedIcon from '@mui/icons-material/RemoveShoppingCartOutlined';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: theme.spacing(1),
            padding: theme.spacing(1),
        },
        paper: {
            padding: theme.spacing(2),
            height: '100%'
        },
        title: {
            fontWeight: 300,
            overflowWrap: 'anywhere',
        },
        grpoup: {
            display: 'block',
            width: '100%',
        },
        header: {
            padding: theme.spacing(2),
        },
        media: {
            height: 160,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        },
        content: {
            height: 130,
        },
        superscript: {
            position: 'relative',
            marginLeft: 2,
            top: '-0.8em',
            // left: '0.2em',
            fontSize: '60%',
            // borderBottom: '1px solid'
        },
        abbr: {
            display: 'inline'
        },
        cat_img: {
            fontSize: 260,
            color: '#ccc',
            textShadow: '0 28px 28px rgba(0,0,0,0.08), 0 10px 10px rgba(0,0,0,0.08)',
        }
    })
);

interface IModel {
    Item: IItem,
    Category: ICategory,
    Contact: IContact
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} {...props} />;
});

// const host = process.env.REACT_APP_HOST;

export default function Item(props: any): ReactElement {
    const classes = useStyles();
    const authority = useAuth();
    const autoHideDuration = 4000;
    const { addRow, contract, order } = useContext(AppContext);

    const [item, setItem] = useState<IItem>();
    const [category, setCategory] = useState<ICategory>();
    const productCode: string = props.match.params.id ? props.match.params.id : '';
    const [token, setToken] = useState<string>('');
    const [notFound, setNotFound] = useState<boolean>(false);
    const [disableAdd, setDisavleAdd] = useState(true);

    const handleAddToShoppingCart = () => {
        if (item) {
            addRow(item, 1);
        }
    };


    useEffect(() => {
        if (order.Contract?.Id === contract?.Id) {
            setDisavleAdd(false);
        } else {
            setDisavleAdd(true);
        }
    }, [order, contract]);

    useEffect(() => {
        const fetchData = async () => {
            const url = `/api/v1.0/catalog/item/${productCode}/${contract?.Id}`;
            // console.log('get data', Date.now(), url);
            await fetch(url, { headers: { authorization: `Bearer ${token}`, accept: 'Accept: application/json' } })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        console.log('DATA', data);
                        if (data.status === 404) {
                            setNotFound(true);
                            setItem(undefined);
                            setCategory(undefined);
                        }

                        const model = data as IModel;
                        setItem(model.Item);
                        setCategory(model.Category);
                        setNotFound(false);
                    }
                })
                .catch((error) => {
                    console.log('Error', error);
                    setNotFound(true);
                    setItem(undefined);
                    setCategory(undefined);
                });
        };

        if (token !== '' && contract && productCode !== '') {
            fetchData();
        }
    }, [token, productCode, contract]);

    useEffect(() => {
        const fetchData = () => {
            if (authority.userData && authority.userData.access_token !== '') {
                setToken(authority.userData.access_token);
            }
        };

        if (authority.userData) {
            fetchData();
        }

    }, [authority.userData]);

    return (
        <div className={classes.root}>
            {
                category && item &&
                <Grid container direction="row" spacing={1} >
                    <Grid item xs={12} sm={5}>
                        <Paper sx={{ paddingTop: 2, boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px' }}>
                            {item.Images.length > 0 &&
                                <ProductSlider images={item.Images} />
                            }

                            {item.Images.length === 0 &&
                                <Typography
                                    display='block'
                                    align='center'
                                    sx={{ lineHeight: '500px', height: '500px' }}>

                                    <span className={clsx(`mdi ${category.Img === '' ? 'mdi-shimmer' : category.Img.toLowerCase()}`, classes.cat_img)}></span>
                                </Typography>
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Paper className='it-box' sx={{ pt: 2, pl: 2, pr: 2, pb: 4 }}>
                            <Typography className={clsx(classes.title, classes.grpoup)} display='block' variant='h6' gutterBottom>
                                <Button
                                    color='inherit'
                                    component={NavLink}
                                    startIcon={<ArrowCircleLeftOutlinedIcon />}
                                    to={`/catalog/${category.Name}`}
                                >
                                    {item.GroupName}
                                </Button>
                            </Typography>

                            <Typography className={clsx(classes.title)} gutterBottom display='block' variant='h4'> {item.Name}</Typography>

                            <table>
                                <tbody>
                                    <tr>
                                        <td ><Typography className={clsx(classes.title)} variant='h6'>Артикул: {item.Article}</Typography></td>
                                        <td rowSpan={3}>
                                            <Fab
                                                disabled={disableAdd}
                                                sx={{ whiteSpace: 'nowrap' }}
                                                variant="extended" size="medium" color="warning"
                                                aria-label="add to shopping cart"
                                                title='Додати до замовлення'
                                                onClick={handleAddToShoppingCart}
                                            >
                                                <AddShoppingCartIcon sx={{ fontSize: 24, mr: 1, ml: 1 }} /> +1 Додати
                                            </Fab>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <Typography className={clsx(classes.title)} variant='h6' gutterBottom>Код: {item.Code}</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td ><Typography className={clsx(classes.title)}> {item.Vendor}</Typography></td>
                                    </tr>

                                    {
                                        item.Prices.map(
                                            (price) => (
                                                <tr key={price.Id}>
                                                    <td style={{ padding: '6px 16px 6px 0px' }}>
                                                        <Typography className={clsx(classes.title)} display='block' variant='subtitle1'>
                                                            <abbr className={classes.abbr} title={price.NameSite}>Ціна, <small style={{ fontSize: '.6em' }}>{price.Currency.Name}</small></abbr>
                                                            {/* <abbr className={classes.abbr} title={price.TypePrice}>{price.NameSite}:</abbr> */}
                                                        </Typography>
                                                    </td>
                                                    <td style={{ padding: '4px 8px' }}>
                                                        <Typography className={classes.title} variant={contract?.TypePrice.Id === price.Id ? 'h3' : 'h6'} color={contract?.TypePrice.Id === price.Id ? 'primary' : 'inherit'} >
                                                            {parseInt(`${price.Value}`)}<span className={classes.superscript}>.{(Math.round(price.Value * 100) / 100).toFixed(2).split('.')[1]}</span>
                                                        </Typography></td>
                                                </tr>
                                            )
                                        )
                                    }
                                </tbody>
                            </table>

                            <Typography style={{ marginTop: 16 }} variant='h6' gutterBottom>Властивості: </Typography>
                            <Divider />
                            <table >
                                <tbody>
                                    {item.Properties.map((property, idx) => (
                                        <tr key={idx}>
                                            <td style={{ padding: '4px 16px 4px 0px' }}><Typography>{property.Name}</Typography></td>
                                            <td style={{ padding: '4px 8px' }}>
                                                {property.Value.startsWith('http') ?
                                                    <Button href={property.Value} color="primary" target="_blank">
                                                        {property.Value}
                                                    </Button> :
                                                    <Typography style={{ padding: '0px 8px' }}>{property.Value}</Typography>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Paper>

                    </Grid>
                </Grid>
            }

            {notFound &&
                <Paper className={classes.header}>
                    <Grid container direction="row" spacing={2} justifyContent='center' >
                        <Grid item>
                            <RemoveShoppingCartOutlinedIcon color="disabled" sx={{ fontSize: 160 }} />
                        </Grid>
                    </Grid>
                    <Typography variant='h5' display='block' textAlign='center' gutterBottom >Вибачте, пока нічого запропонувати</Typography>
                </Paper>
            }
        </div>
    );
}
